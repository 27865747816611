import Web3 from "web3";
import {useEffect, useState} from "react";
import Account from '../../../../share/account/account';
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import iconBelly from "../../../../assest/img/icon-belly.svg"
import iconPlus from "../../../../assest/img/icon-plus.png"
import DataContext from "../../../../share/context/context";
import btc from "../../../../assest/img/button-close.png";
import buttonDisconnect from "../../../../assest/img/button-disconnect.png";
import bccf from "../../../../assest/img/button-claim-confirm.png";
import oriIcon from "../../../../assest/img/icon-ori.svg";
import metamaskIcon from "../../../../assest/img/icon-metamask.png";
import trustwalletIcon from "../../../../assest/img/icon-trustwallet.png";
import Logo from "../../../../assest/img/logo.png";

function Header(isPage) {
    const web3 = new Web3(Web3.givenProvider);
    const [addressWallet, setAddressWallet] = useState('');
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModalDropDow, setShowModalDropDow] = useState(false);
    const [showModalConfirmWithdraw, setShowModalConfirmWithdraw] = useState(false);
    const [showModalWallet, setShowModalWallet] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const addressBaned = [
        '0xB65B27efF165912E6aBFf1A01F8d92f2A3011ad4',
    ]

    async function connectWallet(data) {
        if (window.ethereum) {
            const chainId = await web3.eth.getChainId();
            try {
                if (chainId === Number(process.env.REACT_APP_CHAIN_ID_NUMBER)) {
                    window.ethereum.request({method: 'eth_requestAccounts'}).then(async (accounts) => {
                        setLoadingText('Connect wallet…')
                        setLoading(true);
                        await Account.createAccount(accounts[0]);
                        await setAddressWallet(accounts[0]);
                        await data.updateMercInfo(accounts[0]);
                        localStorage.setItem('address', accounts[0]);
                        await data.getBalanceBelly(accounts[0]);
                        await data.updateStorage();
                        await data.updateAddress();
                        await data.getTotalEgg(accounts[0])
                        setLoading(false)
                    })
                } else {
                    setShowModal3(true);
                    setTitleAlert("Please use Binance SmartChain Mainnet.")
                }
            } catch (e) {

            }
        } else if (window.web3) {
            const chainId = await web3.eth.getChainId();
            if (chainId === Number(process.env.REACT_APP_CHAIN_ID_NUMBER)) {
                await data.updateAddress();
            }
        } else {
            setShowModalWallet(true);
        }
    }

    const numberFormat = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    useEffect(async () => {
        if (localStorage.getItem('address') !== null) {
            setAddressWallet(localStorage.getItem('address'));
        }
    }, [addressWallet])

    async function disconnectWallet() {
        localStorage.removeItem('address');
    }

    const closeModal2 = () => {
        setShowModal2(false)
    }

    const closeModal3 = () => {
        setShowModal3(false)
    }

    const closeModalDropDow = () => {
        setShowModalDropDow(false)
    }

    const closeModalConfirmWithdraw = () => {
        setShowModalConfirmWithdraw(false)
    }

    const closeModalWallet = () => {
        setShowModalWallet(false)
    }

    const withdrawReward = async (address, data) => {
        if (data.storage !== null) {
            const timeStamp = new Date().getTime();
            setLoading(true);
            setLoadingText('Withdrawing.....')
            await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
                if (error) {
                    setLoading(false);
                    setShowModal3(true);
                    setTitleAlert('Withdraw reward fail.');
                    return;
                }
                const res = await Account.withdrawReward(address, timeStamp, signature)
                const withdraw = JSON.parse(res);
                console.log(withdraw)
                if (withdraw.status === 'OK') {
                    setTimeout(async () => {
                        await data.updateAccountInfo(address);
                        await data.getBalanceBelly(address);
                        setShowModal2(true);
                        setTitleAlert(withdraw.data.message);
                        setLoading(false);
                    }, 15000)
                } else {
                    setShowModal3(true);
                    if (withdraw.data) {
                        setTitleAlert(withdraw.data.message);
                    } else {
                        setTitleAlert(withdraw.message);
                    }
                    setLoading(false);
                }
            })
        } else {
            setShowModal3(true);
            setTitleAlert('No wallet connect!');
        }
    }

    return (
        <DataContext.Consumer>
            {data => (
                <header className="col-auto font-riverAdventurer">
                    <div className="row">
                        <div className="col-auto">
                            <ul className="d-flex list-unstyled">
                                <li className="nav-item">
                                    <Link className="nav-link logo" to="/home_page">
                                        <span><img src={Logo} width="80"/></span>
                                    </Link>
                                </li>
                                {
                                    isPage.page === 3 ? (
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/history_battle">
                                                <span>Battle History</span>
                                            </Link>
                                        </li>
                                    ) : (
                                        <></>
                                    )
                                }
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 1 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/merc_center" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>Merc Center</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 2 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/my_crew" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>My Crew</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 3 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/battle_field" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>Battle Field</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 4 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/market" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>Market</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 5 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/history_battle" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>Battle<br/>history</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className={isPage.page === 6 ? "nav-link active" : "nav-link"}*/}
                                {/*          to="/fow" onClick={async () => {*/}
                                {/*    }}>*/}
                                {/*        <span>fow</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div className="col-auto ms-auto">
                            <ul className="d-flex list-unstyled">
                                <li className="nav-item balance d-flex">
                                    <div className="item" onClick={() => {
                                        if (data.storage !== null) {
                                            setShowModalConfirmWithdraw(true);
                                        } else {
                                            setShowModal3(true);
                                            setTitleAlert('No Wallet connect!');
                                        }
                                    }}>
                                        <img src={iconBelly} className="symbol"/>
                                        <a href="#" className="buyMore">
                                            <img src={iconPlus}/>
                                        </a>
                                        <div className="text">UNCLAIM</div>
                                        <div
                                            className="number font-riverAdventurer">
                                            {data.storage !== null ? numberFormat(Math.floor(data.totalReward)) : 0}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={oriIcon} className="symbol"/>
                                        <Link to="/tarven_shop" className="buyMore"><img src={iconPlus}/> </Link>
                                        <div className="text">Available</div>
                                        <div className="number font-riverAdventurer">{numberFormat(data.oriValue)}</div>
                                    </div>
                                </li>
                                {
                                    data.storage === null ? (
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={async () => {
                                                await connectWallet(data);
                                            }}>
                                                <span>Connect Wallet</span>
                                            </a>
                                        </li>
                                    ) : (
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" onClick={() => {
                                                setShowModalDropDow(true);
                                            }}>
                                                <span>
                                                    Wallet <br/>
                                                    <span
                                                        className="quantity">{numberFormat(Math.floor(data.blanceBelly))}</span>
                                                    <img src={iconBelly} alt=""/>
                                                </span>
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <Modal show={showModalWallet} id="connectWalletModal" onHide={closeModalWallet}>
                        <Modal.Body
                            className="commonMessageRibbonFrame d-flex align-items-center justify-content-center">
                            <div className="textHeading font-riverAdventurer">Choose<br/>wallet</div>
                            <img className="closeModal" src={btc} onClick={closeModalWallet} alt=""/>
                            <ul className="listWallet list-unstyled">
                                <li>
                                    <a
                                        href="https://metamask.app.link/dapp/app.cryptopiece.online/"
                                        className="d-flex justify-content-between align-items-center text-white text-decoration-none"
                                    >
                                        <div>Metamask</div>
                                        <div><img src={metamaskIcon} className="icon"/></div>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://link.trustwallet.com/wc?uri=wc%3A1c18afff-be2b-45db-bf02-3964c7275501%401%3Fbridge%3Dhttps%253A%252F%252Fapp.cryptopiece.online%26key%3De9da54acfb075706a8d4f614383cefc9cfba4ba7580132972894b3f678df8140"
                                        className='d-flex justify-content-between align-items-center text-white text-decoration-none'
                                    >
                                        <div>Trust Wallet</div>
                                        <div><img src={trustwalletIcon} className="icon"/></div>
                                    </a>
                                </li>
                            </ul>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModal2} id="buyModal" onHide={closeModal2}>
                        <Modal.Body className="messageStarFrame">
                            <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                            {titleAlert}
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalDropDow} id="disconnectModal" onHide={closeModalDropDow}>
                        <Modal.Body
                            className="commonMessageRibbonFrame disconnectModalFrame d-flex align-items-center justify-content-center">
                            <div className="textHeading font-riverAdventurer">connected<br/>wallet</div>
                            <img className="closeModal" src={btc} alt="" onClick={closeModalDropDow}/>
                            <img src={buttonDisconnect} alt="" className="buttonDisconnect" onClick={async () => {
                                await disconnectWallet();
                                closeModalDropDow();
                                data.removeStorage();
                                data.updateNewMerc(null);
                            }}/>
                            {
                                data.storage !== null ? (
                                    <div>
                                        {
                                            data.address ? (
                                                <div className="walletAddress">
                                                    {data.address.slice(0, 4) + '...' + data.address.slice(data.address.length - 4, data.address.length)}
                                                </div>
                                            ) : (
                                                <div className="walletAddress"></div>
                                            )
                                        }
                                        <div
                                            className="totalBelly font-riverAdventurer">{`${numberFormat(data.blanceBelly.toFixed(2))} `}
                                            <img
                                                src={iconBelly}
                                                alt=""/></div>
                                    </div>
                                ) : ""
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModal3} id="commonMessageModal" centered size="lg" onHide={closeModal3}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModal3}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={loading} id="commonMessageModal" centered size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>{loadingText}</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalConfirmWithdraw} id="claimBellyModal" onHide={closeModalConfirmWithdraw}>
                        <Modal.Body className="commonMessageRibbonFrame">
                            <div className="textHeading font-riverAdventurer">claim belly</div>
                            <img className="closeModal" src={btc} alt="" onClick={closeModalConfirmWithdraw}/>
                            <img src={bccf} alt="" className="buttonClaim" onClick={async () => {
                                closeModalConfirmWithdraw();
                                if (data.amountReward > 0) {
                                    const filterAddressBaned = addressBaned.filter(e => e.toLowerCase() === data.address.toLowerCase());
                                    if (filterAddressBaned.length > 0) {
                                        setShowModal3(true);
                                        setTitleAlert('Your address is having an error during the withdrawal process, cannot make withdraw order.');
                                    } else {
                                        await withdrawReward(data.address, data);
                                    }
                                } else {
                                    setShowModal3(true);
                                    setTitleAlert('No reward to withdraw.');
                                }
                            }}/>
                            Total reward: {data.totalReward}<br/>
                            Reward
                            locked: {data.rewardLocked}<br/>
                            Reward unlock: {data.rewardUnlock}<br/>
                            <div style={{color: '#f26f1d'}}>
                                Withdraw fee:
                                {
                                    data.rewardUnlock === 0 ? "0%" :
                                        data.rewardUnlock < 1000 && data.rewardUnlock > 0 ? '50%' :
                                            data.rewardUnlock >= 1000 && data.rewardUnlock < 1500 ? '10%' :
                                                data.rewardUnlock >= 1500 && data.rewardUnlock < 2250 ? '5%' : '3%'
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </header>
            )}
        </DataContext.Consumer>
    );
}

export default Header;
