import {Modal} from "react-bootstrap";
import arrow from '../../../assest/img/arrow.png';
import fireGif from '../../../assest/img/fire-battle.gif';
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import DataContext from "../../../share/context/context";
import Mercenarie from "../../../share/mercenarie/mercenarie";
import {useEffect, useState} from 'react';
import shadow from "../../../assest/img/shadow.png";
import Account from "../../../share/account/account";
import Web3 from "web3";
import Header from "../../layout/app/header/Header";
import buttonCl from "../../../assest/img/button-claim.png";
import buttonConfirm from "../../../assest/img/button-confirm.png";
import bellyIcon from "../../../assest/img/icon-belly.svg";
import buttonCloseText from "../../../assest/img/button-close-text.png";
import btc from "../../../assest/img/button-close.png";
import buttonCloseMetal from "../../../assest/img/button-close-metal.png";
import buttonFight from "../../../assest/img/button-fight.png";
import buttonSpeedBattle from "../../../assest/img/button-speed-battle.png";
import startSVG from "../../../assest/img/star.svg";
import vsT from "../../../assest/img/vs2.png";
import BuggyGif from "../../../assest/img/Buggy1.gif";
import Demaro from "../../../assest/img/Demaro1.gif";
import Cat from "../../../assest/img/Cat1.gif";
import Disco from "../../../assest/img/Disco1.gif";
import Foxy from "../../../assest/img/foxy1.gif";
import Higuma from "../../../assest/img/Higuma1.gif";
import Cri from "../../../assest/img/cri-octochan.gif";
import torch from '../../../assest/img/torch.gif';
import btnClaimReward from '../../../assest/img/button-claim-reward.png';
import badgeStar from '../../../assest/img/badge-star.png';
import badgeWin from '../../../assest/img/badge-win.png';
import badgeLose from '../../../assest/img/badge-lose.png';

function WantedRoom() {
    const [wantedList, setWantedList] = useState([]);
    const [difficulty, setDifficulty] = useState(0);
    const [showModalWin, setShowModalWin] = useState(false);
    const [showModalLose, setShowModalLose] = useState(false);
    const [mercAttack, setMercAttack] = useState(null);
    const [dataWin, setDataWin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [staminaUsedMer, setStaminaUsedMer] = useState(0);
    const [expRewardMer, setExpRewardMer] = useState(0);
    const [titleAlert, setTitleAlert] = useState('');
    const web3 = new Web3(Web3.givenProvider);
    const [mercList, setMercList] = useState([]);
    const [address, setAddress] = useState('');
    const [mercSelected, setMercSelected] = useState([]);
    const [wantedSelected, setWantedSelected] = useState(null);
    const [attackLoading, setAttackLoading] = useState(false);
    const [speedBattleModal, setSpeedBattleModal] = useState(false);
    const [confirmFightModal, setConfirmFightModal] = useState(false);
    const [fightResultModal, setFightResultModal] = useState(false);
    const [winRate, setWinRate] = useState(0);
    const [indexMerSelected, setIndexMerSelected] = useState(null);
    const [totalExp, setTotalExp] = useState(0);
    const [expLandmark, setExpLandmark] = useState(0);
    const [hitsOfMercySelected, setHitsOfMercySelected] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [rewardArr, setRewardArr] = useState([]);

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(async (accounts) => {
                if (accounts[0]) {
                    setAddress(accounts[0]);
                    checkCookie();
                }
            })
        }
    }, [address])

    const closeModal = () => {
        setShowModal(false)
    }

    const getHitsOfMercySelected = async (number) => {
        try {
            const response = JSON.parse(await Mercenarie.getHitsOfMercySelected(number));
            setHitsOfMercySelected(response.data.hitsAttack);
        } catch (error) {
            console.log(error);
        }
    }

    const getMercByAddress = async (accountAddress) => {
        if (accountAddress) {
            const data = JSON.parse(await Account.getMercByAddress(accountAddress));
            setMercList(data);
            return data;
        }
    }

    const getAccountDetail = async (accountAddress, listMerc) => {
        if (accountAddress) {
            const account = JSON.parse(await Account.getAccountByAddress(accountAddress));
            await getMercSelected(account.mercSelected, listMerc)
        }
    }

    const getMercSelected = async (mercSelected, listMerc) => {
        if (listMerc.length > 0) {
            const arrMercSelected = [];
            for (let i = 0; i < mercSelected.length; i++) {
                const elm = mercSelected[i];
                const filter = listMerc.filter(e => e.number === Number(elm))
                if (filter[0]) {
                    arrMercSelected.push(filter[0])
                }
            }
            setMercSelected(arrMercSelected);
            if (mercAttack !== null) {
                const getMercAttack = arrMercSelected.filter(e => e.number === mercAttack.number);
                const totalExpArr = [
                    30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
                    450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
                    1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
                    3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
                ]
                const expLandmarkArr = [
                    0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
                    3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
                    14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
                    39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
                ]
                setTotalExp(totalExpArr[getMercAttack[0].level - 1])
                setExpLandmark(expLandmarkArr[getMercAttack[0].level - 1])
                setMercAttack(getMercAttack[0]);
            }
        }
    }

    const randomWantedList = () => {
        const totalWanted = [Cat, BuggyGif, Higuma, Higuma, Cri, Foxy, Disco, Demaro];
        const totalElements = [water, fire, earth, wood, dark, metal, light];
        const elementsText = ['water', 'fire', 'earth', 'wood', 'dark', 'metal', 'light'];
        const randomList = [];
        for (let i = 0; i < 4; i++) {
            const randomWanted = Math.floor(Math.random() * 8);
            const randomElement = Math.floor(Math.random() * 7);
            const obj = {
                wanted: totalWanted[randomWanted],
                element: totalElements[randomElement],
                elementsText: elementsText[randomElement],
                number: i + 1,
            }
            randomList.push(obj);
        }
        setWantedList(randomList);
        return randomList;
    }

    useEffect(() => {
        if (window.ethereum && window.web3) {
            window.ethereum.on('accountsChanged', async (accounts) => {
                if (accounts.length > 0) {
                    setMercAttack(null)
                    window.ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                        if (accounts[0]) {
                            setAddress(accounts[0]);
                        }
                    })
                } else {
                    setMercAttack(null)
                }
            });
        }
    }, [])

    const sign = async (number, address, data, stamina) => {
        if (difficulty > 0) {
            if (data.mercSelected.length > 0) {
                setAttackLoading(true)
                const timeStamp = new Date().getTime();
                await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
                    if (error) {
                        setAttackLoading(false)
                        return;
                    }
                    await attackWanted(number, address, data, stamina, timeStamp, signature);
                })
            } else {
                setShowModal(true);
                setTitleAlert('No Merc Selected. Please try again later.');
            }
        } else {
            setAttackLoading(false)
            setShowModal(true);
            setTitleAlert('No Enemy has been selected yet! Please choose Enemy before fighting!');
        }
    }

    const attackWanted = async (number, address, data, stamina, dataThatWasSigned, signature) => {
        if (stamina - staminaUsedMer >= 30) {
            const res = JSON.parse(await Mercenarie.attack(number, difficulty, address, dataThatWasSigned, signature));
            console.log(res)
            if (res.status === "SUCCESS") {
                await data.updateMercInfo(address);
                await data.updateAccountInfo(address);
                setAttackLoading(false)
                setShowModalWin(true);
                setDataWin(res.data);
                const resData = await getMercByAddress(address);
                await getAccountDetail(address, resData)
                await getHitsOfMercySelected(number);
            } else if (res.status === "FAIL") {
                setTimeout(async () => {
                    if (res.message === "You have reached the limit of attacks") {
                        setAttackLoading(false)
                        setTitleAlert(res.message);
                        setShowModal(true);
                    } else {
                        await data.updateMercInfo(address);
                        setAttackLoading(false)
                        const resData = await getMercByAddress(address);
                        await getAccountDetail(address, resData)
                        setShowModalLose(true);
                        await getHitsOfMercySelected(number);
                    }
                }, 5000)
            } else if (res.status === "ERR") {
                if (res.data.message === "Merc is not selected attack") {
                    setAttackLoading(false)
                    setTitleAlert("Please confirm the team in MyCrew page to join the battle.");
                    setShowModal(true);
                } else {
                    await getHitsOfMercySelected(number);
                    await data.updateMercInfo(address);
                    const resData = await getMercByAddress(address);
                    await getAccountDetail(address, resData)
                    setAttackLoading(false)
                    setTitleAlert(res.data.message);
                    setShowModal(true);
                }
            }
        } else {
            setAttackLoading(false)
            setShowModal(true);
            setTitleAlert('You don\'t have enough stamina! You need at least 30 stamina to fight a criminal!');
        }
    }

    const updateMecrAttack = async (mecr, index) => {
        if (address) {
            setStaminaUsedMer(0);
            setExpRewardMer(0);
            setMercAttack(mecr);
            setDifficulty(0);
            setWantedSelected(null);
            const totalExpArr = [
                30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
                450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
                1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
                3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
            ]
            const expLandmarkArr = [
                0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
                3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
                14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
                39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
            ]
            setTotalExp(totalExpArr[mecr.level - 1])
            setExpLandmark(expLandmarkArr[mecr.level - 1])
            await getHitsOfMercySelected(mecr.number);
            if (index === 1) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected1")));
            } else if (index === 2) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected2")))
            } else if (index === 3) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected3")))
            } else if (index === 4) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected4")))
            }
        }
    }

    const closeModalWin = () => {
        setShowModalWin(false)
    }

    const closeModalLose = () => {
        setShowModalLose(false)
    }

    const updateDifficulty = async (value) => {
        if (localStorage.getItem('address') !== null) {
            const data = {};
            data.wanted = wantedList[value - 1].wanted;
            data.element = wantedList[value - 1].element;
            data.number = wantedList[value - 1].number;
            data.elementsText = wantedList[value - 1].elementsText;
            if (value - 1 === 0) {
                data.winRate = 70;
            } else if (value - 1 === 1) {
                data.winRate = 50;
            } else if (value - 1 === 2) {
                data.winRate = 35;
            } else if (value - 1 === 3) {
                data.winRate = 20;
            }
            changeWinRate(data.winRate, mercAttack, data.elementsText);
            setDifficulty(value);
            setWantedSelected(data);
        } else {
            setShowModal(true);
            setTitleAlert('No wallet connect');
        }
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        const cookieTime = getCookie("timeStamp");
        let currecntTime = new Date().getTime();
        if (cookieTime !== "") {
            if (currecntTime - Number(cookieTime) > 3600000) {
                setCookie("timeStamp", currecntTime, 30);
                for (let i = 0; i < 4; i++) {
                    localStorage.setItem(`mercSelected${i + 1}`, JSON.stringify(randomWantedList()));
                    localStorage.setItem(`winRate1Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate2Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate3Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate4Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate5Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate6Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate7Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate8Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate9Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate10Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`randomUpDowIndex${i}`, Math.floor(Math.random() * (2 - 1 + 1)) + 1);
                    localStorage.setItem(`randomLightDarkIndex${i}`, Math.floor(Math.random() * (10 + 1)));
                }
            } else {
                setWantedList(null)
            }
        } else {
            let time = new Date().getTime();
            setCookie("timeStamp", time, 30);
            for (let i = 0; i < 4; i++) {
                localStorage.setItem(`mercSelected${i + 1}`, JSON.stringify(randomWantedList()));
                localStorage.setItem(`winRate1Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate2Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate3Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate4Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate5Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate6Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate7Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate8Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate9Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate10Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`randomUpDowIndex${i}`, Math.floor(Math.random() * (2 - 1 + 1)) + 1);
                localStorage.setItem(`randomLightDarkIndex${i}`, Math.floor(Math.random() * (10 + 1)));
            }
        }
    }

    const changeWinRate = (currentWinRate, merElement, wantedElement) => {
        if (merElement !== null) {
            let winRate = currentWinRate;
            if (merElement.element === 'fire' && wantedElement === 'water') {
                winRate = winRate - Number(localStorage.getItem(`winRate1Index${indexMerSelected}`));
            } else if (merElement.element === 'fire' && wantedElement === 'wood') {
                winRate = winRate + Number(localStorage.getItem(`winRate2Index${indexMerSelected}`));
            } else if (merElement.element === 'earth' && wantedElement === 'metal') {
                winRate = winRate - Number(localStorage.getItem(`winRate3Index${indexMerSelected}`));
            } else if (merElement.element === 'earth' && wantedElement === 'water') {
                winRate = winRate + Number(localStorage.getItem(`winRate4Index${indexMerSelected}`));
            } else if (merElement.element === 'water' && wantedElement === 'earth') {
                winRate = winRate - Number(localStorage.getItem(`winRate5Index${indexMerSelected}`));
            } else if (merElement.element === 'water' && wantedElement === 'fire') {
                winRate = winRate + Number(localStorage.getItem(`winRate6Index${indexMerSelected}`));
            } else if (merElement.element === 'metal' && wantedElement === 'wood') {
                winRate = winRate - Number(localStorage.getItem(`winRate7Index${indexMerSelected}`));
            } else if (merElement.element === 'metal' && wantedElement === 'earth') {
                winRate = winRate + Number(localStorage.getItem(`winRate8Index${indexMerSelected}`));
            } else if (merElement.element === 'wood' && wantedElement === 'fire') {
                winRate = winRate - Number(localStorage.getItem(`winRate9Index${indexMerSelected}`));
            } else if (merElement.element === 'wood' && wantedElement === 'metal') {
                winRate = winRate + Number(localStorage.getItem(`winRate10Index${indexMerSelected}`));
            } else if ((merElement.element === 'light' && wantedElement === 'dark') || (merElement === 'dark' && wantedElement === 'light')) {
                const randomUpDow = localStorage.getItem(`randomUpDowIndex${indexMerSelected}`);
                if (randomUpDow === 1) {
                    winRate = winRate - Number(localStorage.getItem(`randomLightDarkIndex${indexMerSelected}`));
                } else {
                    winRate = winRate + Number(localStorage.getItem(`randomLightDarkIndex${indexMerSelected}`));
                }
            }
            setWinRate(winRate);
        } else {
            setWinRate(currentWinRate);
        }
    }

    const closeModalSpeedBattle = () => {
        setSpeedBattleModal(false);
    }

    const closeConfirmFightModal = () => {
        setConfirmFightModal(false);
    }

    const closeFightResultModal = () => {
        setFightResultModal(false);
    }

    const onSpeedAttack = async (data, address) => {
        if (difficulty > 0) {
            if (data.mercSelected.length > 0) {
                setAttackLoading(true)
                setConfirmFightModal(false);
                setSpeedBattleModal(false);
                const timeStamp = new Date().getTime();
                await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
                    if (error) {
                        setAttackLoading(false)
                        return;
                    }
                    const res = JSON.parse(await Mercenarie.speedAttack(address, difficulty, timeStamp, signature));
                    console.log(res);
                    if (res.status === 200) {
                        setTotalReward(res.data.totalReward);
                        setRewardArr(res.data.rewardArr);
                        await data.updateMercInfo(address);
                        await data.updateAccountInfo(address);
                        const resData = await getMercByAddress(address);
                        await getAccountDetail(address, resData)
                        setFightResultModal(true);
                        setAttackLoading(false);
                    } else {
                        setAttackLoading(false)
                        setTitleAlert(res.message);
                        setShowModal(true);
                    }
                })
            } else {
                setConfirmFightModal(false);
                setShowModal(true);
                setTitleAlert('No Merc Selected. Please try again later.');
            }
        } else {
            setConfirmFightModal(false);
            setAttackLoading(false)
            setShowModal(true);
            setTitleAlert('No Enemy has been selected yet! Please choose Enemy before fighting!');
        }
    }

    return (
        <DataContext.Consumer>
            {
                data => (
                    <div className="wrap" id="battleFieldPage">
                        <Header page={3} saveMerc={data.saveMercSlt}/>
                        <div id="content" className="col d-flex align-items-center justify-content-end">
                            <img src={fireGif} alt="" className="fire-battle"/>
                            <img src={fireGif} alt="" className="fire-battle right"/>
                            <div className="battleFieldFrame d-flex">
                                <div className="yourTeam">
                                    {
                                        data.storage !== null && data.mercSelected.length > 0 && data.mercSelected ? (
                                            <div className="listTeam d-flex justify-content-center">
                                                {
                                                    data.mercSelected.map((e, i) => (
                                                        <div
                                                            className={mercAttack ? mercAttack.number === e.number ? "merc  selected" : "merc" : "merc"}
                                                            onClick={async () => {
                                                                await updateMecrAttack(e, i + 1, data);
                                                                setIndexMerSelected(i);
                                                                if (wantedSelected !== null) {
                                                                    changeWinRate(wantedSelected.winRate, e, wantedSelected.elementsText);
                                                                }
                                                            }}
                                                            key={i}
                                                        >
                                                            <img
                                                                src={
                                                                    e.element === "fire" ? fire :
                                                                        e.element === "earth" ? earth :
                                                                            e.element === "metal" ? metal :
                                                                                e.element === "water" ? water :
                                                                                    e.element === "wood" ? wood :
                                                                                        e.element === "dark" ? dark :
                                                                                            light
                                                                }
                                                                alt=""
                                                                className="element"
                                                            />
                                                            <div
                                                                className={
                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "thumbMercWrap merc-cross" :
                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "thumbMercWrap merc-broke" : "thumbMercWrap"
                                                                }
                                                            >
                                                                <img src={e.avatar} alt=""/>
                                                            </div>
                                                            <div className="star">
                                                                {
                                                                    data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                        <img src={startSVG} alt=""
                                                                             key={i}/>
                                                                    ))
                                                                }
                                                            </div>
                                                            {
                                                                mercAttack !== null && mercAttack.number === e.number ? (
                                                                    <div className="text">selected</div>
                                                                ) : ""
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            <div className="listTeam d-flex justify-content-center">
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    data.storage !== null ? (
                                        <div className="col mercTeam d-flex justify-content-start">
                                            {
                                                mercAttack !== null ? (
                                                    <div className="row overflow-hidden">
                                                        <div className="col-12">
                                                            <div className="charId">
                                                                <img
                                                                    src={
                                                                        mercAttack.element === "fire" ? fire :
                                                                            mercAttack.element === "earth" ? earth :
                                                                                mercAttack.element === "metal" ? metal :
                                                                                    mercAttack.element === "water" ? water :
                                                                                        mercAttack.element === "wood" ? wood :
                                                                                            mercAttack.element === "dark" ? dark :
                                                                                                light
                                                                    }
                                                                    alt=""
                                                                    className="element"
                                                                />
                                                                id: {mercAttack.number}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                mercAttack ?
                                                                    mercAttack.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "col d-flex s-char merc-cross" :
                                                                        mercAttack.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "col d-flex s-char merc-broke" :
                                                                            "col d-flex" : "col d-flex "
                                                            }
                                                        >
                                                            <div className="charInfo">
                                                                <div className="star">
                                                                    {
                                                                        data.checkStarRarity(mercAttack.rarityNumber).map((elm, i) => (
                                                                            <img src={startSVG} alt=""
                                                                                 key={i}/>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="charStamina">
                                                                    <div
                                                                        className="d-flex justify-content-between text">
                                                                        <div>stamina: {mercAttack.stamina - staminaUsedMer}/150</div>
                                                                        <div>{Math.floor((mercAttack.stamina - staminaUsedMer) * 100 / 150)}%</div>
                                                                    </div>
                                                                    <div className="processBar">
                                                                        <div className="percent"
                                                                             style={{width: `${Math.floor((mercAttack.stamina - staminaUsedMer) * 100 / 150)}%`}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="charXp">
                                                                    <div
                                                                        className="d-flex justify-content-between text">
                                                                        <div>Xp: {(mercAttack.exp + expRewardMer - expLandmark)}/{totalExp} -
                                                                            LV.{mercAttack.level}</div>
                                                                        <div>{Math.floor((mercAttack.exp + expRewardMer - expLandmark) * 100 / totalExp)}%</div>
                                                                    </div>
                                                                    <div className="processBar">
                                                                        <div className="percent"
                                                                             style={{width: `${Math.floor((mercAttack.exp + expRewardMer - expLandmark) * 100 / totalExp)}%`}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="remainingMatches">
                                                                    Remaining
                                                                    matches: <span>{hitsOfMercySelected}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto mainImg">
                                                                <img src={mercAttack.avatar}
                                                                     alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row overflow-hidden">

                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div className="col mercTeam d-flex justify-content-start">

                                        </div>
                                    )
                                }
                                <div className="mercenary d-flex align-items-end justify-content-around">
                                    <div className="d-flex align-items-center">
                                        <div><img src={water} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={fire} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={metal} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={wood} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={earth} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={water} alt="" className="element"/></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div><img src={light} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={arrow} alt="" className="arrow arrow-left"/></div>
                                        <div><img src={dark} alt="" className="element"/></div>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end text-center">
                                    <div className="winingRate">
                                        <img src={vsT} alt=""/>
                                        {
                                            wantedSelected !== null && data.storage !== null ? (
                                                <div className="text">Winning rate: {winRate}%</div>
                                            ) : (
                                                <div className="text">

                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="fightFrame">
                                        <a href="#" className="btnFight btnCustom"
                                           onClick={async () => {
                                               if (localStorage.getItem('address') !== null) {
                                                   if (mercAttack !== null) {
                                                       await sign(mercAttack.number, data.address, data, mercAttack.stamina);
                                                   } else {
                                                       setShowModal(true);
                                                       setTitleAlert('No Mercenary has been selected yet! Please choose Mercenary before fighting!');
                                                   }
                                               } else {
                                                   setTitleAlert('No wallet connect.');
                                                   setShowModal(true);
                                               }
                                           }}
                                        >
                                            <img src={buttonFight} alt=""/>
                                        </a>
                                        <a
                                            href="#"
                                            className="speedBattle"
                                            onClick={() => {
                                                setSpeedBattleModal(true);
                                                setDifficulty(0);
                                                setWantedSelected(null);
                                            }}
                                        >
                                            <img src={buttonSpeedBattle} alt=""/>
                                        </a>
                                    </div>
                                </div>
                                <div className="col criTeam d-flex justify-content-end">
                                    <div
                                        className={
                                            wantedSelected ?
                                                wantedSelected.wanted === Cat ?
                                                    "d-flex overflow-hidden s-char cri-catnip" :
                                                    "d-flex overflow-hidden" : "d-flex overflow-hidden"}
                                    >
                                        <div className="col mainImg align-self-end">
                                            {
                                                wantedSelected !== null && data.storage !== null ? (
                                                    <img src={wantedSelected.wanted} alt=""/>
                                                ) : ""
                                            }
                                        </div>
                                        <div className="col-auto align-self-center">
                                            {
                                                data.storage !== null && wantedList ? (
                                                    <ul className="listCri list-unstyled">
                                                        {
                                                            wantedList.map((e, i) => (
                                                                <li
                                                                    className={wantedSelected !== null && wantedSelected.number === e.number ? "selected" : ""}
                                                                    onClick={async () => {
                                                                        await updateDifficulty(i + 1);
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    <img src={e.element} alt="" className="element"/>
                                                                    <div className="thumbCriWrap">
                                                                        <img src={e.wanted} alt=""/>
                                                                    </div>
                                                                    {
                                                                        wantedSelected !== null && wantedSelected.number === e.number ? (
                                                                            <div className="text">selected</div>
                                                                        ) : ""
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                ) : (
                                                    <ul className="listCri list-unstyled">
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={showModalWin} id="winModal" centered size="lg" onHide={closeModalWin}>
                            <Modal.Body className="winFrame">
                                <img className="closeModal" src={buttonCl} alt="" onClick={closeModalWin}/>
                                Congratulation! <br/> You captured a criminal! You earned <br/>
                                {
                                    dataWin === null ? (
                                        <div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                className="d-flex justify-content-center align-items-center bellyReceived">
                                                <div className="font-riverAdventurer">{Math.floor(dataWin.reward)}</div>
                                                <img src={bellyIcon} alt=""/>
                                            </div>
                                            <div className="xpReceived">+{dataWin.exp} XP</div>
                                        </div>
                                    )
                                }
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModalLose} onHide={closeModalLose} id="lossModal" centered size="lg">
                            <Modal.Body className="lossFrame">
                                <img className="closeModal" src={buttonCloseText} alt="" onClick={closeModalLose}/>
                                Too bad, the criminal has escaped!
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModal} id="commonMessageModal" centered size="lg" onHide={closeModal}>
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                                <div>
                                    <p>{titleAlert}</p>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={attackLoading} id="commonMessageModal" centered size="lg">
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <div>
                                    <p>You found a wanted criminal! Please wait for fight result…</p>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={speedBattleModal} id="speedBattleModal" centered size="xl"
                               onHide={closeModalSpeedBattle}>
                            <Modal.Body className="mercFrameMetal large d-flex flex-column">
                                <img className="closeModal" src={buttonCloseMetal} onClick={closeModalSpeedBattle}/>
                                <div className="col-auto row align-items-center justify-content-center">
                                    <h3 className="title font-riverAdventurer col-auto text-start">Choose level</h3>
                                    <h3 className="title font-riverAdventurer col-auto text-center">your team</h3>
                                </div>
                                <div className="col row align-items-center justify-content-center">
                                    <div className="col-auto">
                                        <div className="level">
                                            <ul className="list-unstyled list-unstyled d-inline-block m-0">
                                                <li onClick={() => {
                                                    setDifficulty(1)
                                                }}>
                                                    <input type="radio" name="level"/>
                                                    <div className="winingRate">
                                                        <div>Winning rate: 70%</div>
                                                        <div>EXP: 40-60</div>
                                                        <div>Reward: 14-16 <img src={bellyIcon} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="status">Selected</div>
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setDifficulty(2)
                                                    }}
                                                >
                                                    <input type="radio" name="level"/>
                                                    <div className="winingRate">
                                                        <div>Winning rate: 50%</div>
                                                        <div>EXP: 60-70</div>
                                                        <div>Reward: 16-24 <img src={bellyIcon} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="status">Selected</div>
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setDifficulty(3)
                                                    }}
                                                >
                                                    <input type="radio" name="level"/>
                                                    <div className="winingRate">
                                                        <div>Winning rate: 35%</div>
                                                        <div>EXP: 70-80</div>
                                                        <div>Reward: 26-40 <img src={bellyIcon} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="status">Selected</div>
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setDifficulty(4)
                                                    }}
                                                >
                                                    <input type="radio" name="level"/>
                                                    <div className="winingRate">
                                                        <div>Winning rate: 20%</div>
                                                        <div>EXP: 80-100</div>
                                                        <div>Reward: 42-60<img src={bellyIcon} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="status">Selected</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="crewBoard">
                                            <ul className="list-unstyled listCrew m-0">
                                                {
                                                    data.storage !== null && data.mercSelected.length > 0 && data.mercSelected ? (
                                                        <>
                                                            {
                                                                data.mercSelected.map((e, i) => (
                                                                    <li className={
                                                                        e.max_battle_over_one_day === 0 ? 'row g-0 disabled' : 'row g-0'
                                                                    } key={i}>
                                                                        <div className="col-auto">
                                                                            <div className="merc">
                                                                                <img
                                                                                    src={
                                                                                        e.element === "fire" ? fire :
                                                                                            e.element === "earth" ? earth :
                                                                                                e.element === "metal" ? metal :
                                                                                                    e.element === "water" ? water :
                                                                                                        e.element === "wood" ? wood :
                                                                                                            e.element === "dark" ? dark :
                                                                                                                light
                                                                                    }
                                                                                    className="element"
                                                                                />
                                                                                <div className="mercOverview">
                                                                                    <div className="thumbMercWrap">
                                                                                        <img
                                                                                            className={
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                            }
                                                                                            src={e.avatar}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="info">
                                                                                        <div className="star">
                                                                                            {
                                                                                                data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                                    <img src={startSVG}
                                                                                                         alt=""
                                                                                                         key={i}/>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="charStamina">
                                                                                <div
                                                                                    className="d-flex justify-content-between text">
                                                                                    <div>stamina: {e.stamina}/150</div>
                                                                                    <div>{Math.floor((e.stamina) * 100 / 150)}%</div>
                                                                                </div>
                                                                                <div className="processBar">
                                                                                    <div
                                                                                        className="percent"
                                                                                        style={{width: `${Math.floor((e.stamina) * 100 / 150)}%`}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="cost">
                                                                                Cost 4 <img src={bellyIcon}
                                                                                             className="ms-1"/>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <img className="torch" src={torch}/>
                                <img className="torch right" src={torch}/>
                                <div className="inputGroup d-flex align-items-center">
                                    <div className="inputIcon d-flex align-items-center">
                                        Cost {data.mercSelected.filter(e => e.max_battle_over_one_day !== 0).length * 4}
                                        <img src={bellyIcon} className="symbol"/>
                                    </div>
                                    <button className="buttonText" onClick={() => {
                                        if (data.mercSelected.filter(e => e.max_battle_over_one_day !== 0).length * 4 > 0) {
                                            setConfirmFightModal(true);
                                        }
                                    }}>
                                        <img src={buttonSpeedBattle} alt=""/>
                                    </button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={confirmFightModal} id="confirmFightModal" centered onHide={closeConfirmFightModal}
                               className="modalOverlay">
                            <Modal.Body
                                className="commonMessageRibbonFrame confirmFightModalFrame d-flex align-items-center justify-content-center">
                                <div className="textHeading font-riverAdventurer">Speed battle</div>
                                <img className="closeModal" src={btc} alt="" data-bs-dismiss="modal"
                                     onClick={closeConfirmFightModal}/>
                                <img src={buttonConfirm} alt="" className="buttonConfirm" onClick={async () => {
                                    await onSpeedAttack(data, data.address)
                                }}/>
                                <div className="inner">
                                    <div>
                                        Winning Rate:
                                        {difficulty === 1 ? ' 70%' : difficulty === 2 ? " 50%" : difficulty === 3 ? " 35%" : difficulty === 4 ? " 20%" : " 0%"}
                                    </div>
                                    <div>
                                        Cost: {data.mercSelected.filter(e => e.max_battle_over_one_day !== 0).length * 4} Belly
                                        <img src={bellyIcon} alt=""/>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={fightResultModal} id="fightResultModal" backdropClassName="static" centered
                               size="lg" fullscreen>
                            <Modal.Body className="listReward">
                                <div className="inner">
                                    <div className="text-center font-riverAdventurer top">
                                        <p className="mb-1"><img src={badgeStar} alt="" width="250"/></p>
                                        <h5>Battle result</h5>
                                    </div>
                                    <div className="row g-0 justify-content-center align-items-center">
                                        {
                                            data.storage !== null && data.mercSelected.length > 0 && data.mercSelected ? (
                                                <>
                                                    {
                                                        data.mercSelected.map((e, i) => (
                                                            <div className="col-auto" key={i}>
                                                                <div className="xp">
                                                                    +
                                                                    {
                                                                        rewardArr.filter(elm => e.number === elm.number).length > 0 ? `${rewardArr.filter(elm => e.number === elm.number)[0].reward.totalExp} ` : " "
                                                                    }
                                                                    XP
                                                                </div>
                                                                <div className="signSuccess">
                                                                    <img
                                                                        className={
                                                                            e ?
                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character merc-broke" :
                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "character merc-cardinal" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character merc-cross" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "character merc-helsing" :
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "character merc-loogie" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "character merc-monie" :
                                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "character merc-sharksan" :
                                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "character merc-slinger" : "" : ""
                                                                        }
                                                                        src={e.avatar}
                                                                        alt=""
                                                                    />
                                                                    <div className="charId">
                                                                        <img
                                                                            src={
                                                                                e.element === "fire" ? fire :
                                                                                    e.element === "earth" ? earth :
                                                                                        e.element === "metal" ? metal :
                                                                                            e.element === "water" ? water :
                                                                                                e.element === "wood" ? wood :
                                                                                                    e.element === "dark" ? dark :
                                                                                                        light
                                                                            }
                                                                            alt=""
                                                                            className="element"
                                                                        />
                                                                        id: {e.number}
                                                                    </div>
                                                                    <div className="star">
                                                                        {
                                                                            data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                <img src={startSVG} alt=""
                                                                                     key={i}/>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center stats">
                                                                    <div>
                                                                        <img src={badgeWin} alt=""/>
                                                                        <div className="number">
                                                                            {
                                                                                rewardArr.filter(elm => e.number === elm.number).length > 0 ? rewardArr.filter(elm => e.number === elm.number)[0].reward.countWin : " "
                                                                            }
                                                                        </div>
                                                                        <div className="text">Win</div>
                                                                    </div>
                                                                    <div>
                                                                        <img src={badgeLose} alt=""/>
                                                                        <div className="number">
                                                                            {
                                                                                rewardArr.filter(elm => e.number === elm.number).length > 0 ? rewardArr.filter(elm => e.number === elm.number)[0].reward.countLose : " "
                                                                            }
                                                                        </div>
                                                                        <div className="text">Lose</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                    <div className="bonus text-center font-riverAdventurer">
                                        +{totalReward} <img className="icon" src={bellyIcon} alt=""/>
                                    </div>
                                    <div className="text-center mt-3">
                                        <img src={btnClaimReward} alt="" width="160" onClick={closeFightResultModal}/>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            }
        </DataContext.Consumer>
    );
}

export default WantedRoom;
