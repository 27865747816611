import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/layout.css';
import MercCenter from "./compoment/screen/mercCenter/MercCenter";
import MyCrew from "./compoment/screen/myCrew/MyCrew";
import WantedRoom from "./compoment/screen/battleField/WantedRoom";
import DataContext from "./share/context/context";
import Account from "./share/account/account";
import {useEffect, useState} from "react";
import Web3 from "web3";
import {Route, Redirect, Switch} from 'react-router-dom'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Vesting from "./compoment/layout/vesting/vesting";
import Market from "./compoment/screen/market/market"
import MyShop from "./compoment/screen/myShop/MyShop"
import NewMyCrew from "./compoment/screen/myCrew/NewMyCrew";
import LevelUp from "./compoment/screen/myCrew/LevelUp";
import Upgrade from "./compoment/screen/myCrew/Upgrade";
import SellMerc from "./compoment/screen/sellMerc/SellMerc"
import HistoryBattle from "./compoment/screen/battleField/history";
import SellMercDetail from "./compoment/screen/sellMerc/SellMercDetail";
import BuyPackage from "./compoment/screen/mercCenter/BuyPackage";
import {Modal} from "react-bootstrap";
import {ethers} from "ethers";
import Fow from "./compoment/screen/fow/fow";
import TarvenShop from "./compoment/screen/market/TarvenShop";
import HomePage from "./compoment/screen/homePage/HomePage";


const routes = [
    {
        path: '/merc_center',
        component: MercCenter,
    },
    {
        path: '/home_page',
        component: HomePage,
    },
    {
        path: '/tarven_shop',
        component: TarvenShop,
    },
    {
        path: '/team_setting',
        component: MyCrew,
    },
    {
        path: '/fow',
        component: Fow,
    },
    {
        path: '/level_up',
        component: LevelUp,
    },
    {
        path: '/upgrade',
        component: Upgrade,
    },
    {
        path: '/my_crew',
        component: NewMyCrew,
    },
    {
        path: '/battle_field',
        component: WantedRoom,
    },
    {
        path: '/token_vesting',
        component: Vesting
    },
    {
        path: '/market',
        component: Market
    },
    {
        path: '/my_shop',
        component: MyShop
    },
    {
        path: '/sell_merc',
        component: SellMerc
    },
    {
        path: '/detail',
        component: SellMercDetail
    },
    {
        path: '/history_battle',
        component: HistoryBattle
    },
    {
        path: '/buy_package',
        component: BuyPackage
    }
]



function App() {
    const [text, setText] = useState(0);
    const [mercList, setMercList] = useState([]);
    const [address, setAddress] = useState('');
    const [mercSelected, setMercSelected] = useState([]);
    const [bellyBalance, setBellyBalance] = useState(0);
    const [storage, setStorage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listEgg, setListEgg] = useState(0);
    const web3 = new Web3(Web3.givenProvider);
    const connectContractBelly = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BELLY_ABI), process.env.REACT_APP_BELLY_ADDRESS);
    const connectContractmercenary = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MERCENARY_ABI), process.env.REACT_APP_MERCENARY_ADDRESS);
    const [newMerc, setNewMerc] = useState(null);
    const [saveMercSlt, setSaveMercSlt] = useState(true);
    const [amountReward, setAmountReward] = useState(0);
    const [oriValue, setOriValue] = useState(0);
    const [accountId, setAccountId] = useState('');
    const [balanceOCoin, setBalanceOCoin] = useState(0);
    const [balanceBCoin, setBalanceBCoin] = useState(0);
    const [rewardUnlock, setRewardUnlock] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [rewardLocked, setRewardLocked] = useState(0);

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            const chainId = await web3.eth.getChainId()
            if (chainId === Number(process.env.REACT_APP_CHAIN_ID_NUMBER)) {
                setStorage(localStorage.getItem('address'));
                window.ethereum.request({method: 'eth_requestAccounts'}).then(async (accounts) => {
                    if (accounts[0]) {
                        localStorage.setItem('address', accounts[0]);
                        setStorage(accounts[0]);
                        setAddress(accounts[0]);
                        const resdata = await getMercByAddress(accounts[0]);
                        await getAccountDetail(accounts[0], resdata);
                        await getBalanceBelly(accounts[0]);
                        await getTotalEgg(accounts[0]);
                    }
                })
            }
        }
    }, [])

    useEffect(async () => {
        if (address.length > 0) {
            await Account.createAccount(address);
        }
    }, [address])

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: process.env.REACT_APP_CHAIN_ID_HEX}],
                });
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: process.env.REACT_APP_CHAIN_ID_HEX,
                                    rpcUrl: process.env.REACT_APP_RPC_URL,
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                console.error(error);
            }
        }
    })

    useEffect(() => {
        if (window.ethereum && window.web3) {
            window.ethereum.on('accountsChanged', async (accounts) => {
                if (accounts.length > 0) {
                    setLoading(true);
                    setMercSelected([]);
                    localStorage.removeItem('address');
                    localStorage.setItem('address', accounts[0]);
                    setAddress(accounts[0]);
                    const resdata = await getMercByAddress(accounts[0]);
                    await getBalanceBelly(accounts[0]);
                    await getTotalEgg(accounts[0]);
                    await getAccountDetail(accounts[0], resdata);
                    updateNewMerc(null);
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);
                } else {
                    setMercSelected([]);
                    localStorage.removeItem('address');
                    setStorage(null)
                    updateNewMerc(null);
                }
            });
        }
    }, [])

    useEffect(() => {
        if (window.ethereum && window.web3) {
            window.ethereum.on('chainChanged', async (chainId) => {
                if (chainId !== process.env.REACT_APP_CHAIN_ID_HEX) {
                    localStorage.removeItem('address');
                    setStorage(null)
                    updateNewMerc(null);
                } else {
                    window.ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                        if (accounts[0]) {
                            localStorage.setItem('address', accounts[0]);
                            setStorage(accounts[0]);
                        }
                    })
                }
            });
        }
    })

    const getTotalEgg = async (address) => {
        connectContractmercenary.methods.eggs(address).call((err, res) => {
            if (err) {
                return;
            }
            setListEgg(res);
        })
    }

    const updateMercInfo = async (address) => {
        const resdata = await getMercByAddress(address);
        await getAccountDetail(address, resdata);
    }

    const getMercByAddress = async (accountAddress) => {
        if (accountAddress) {
            const data = JSON.parse(await Account.getMercByAddress(accountAddress));
            setMercList(data);
            return data;
        }
    }

    const updateAddress = async () => {
        window.ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
            if (accounts[0]) {
                setAddress(accounts[0]);
            }
        })
    }

    async function getBalanceBelly(addressWl) {
        if (addressWl) {
            connectContractBelly.methods.balanceOf(addressWl).call(function (err, res) {
                if (err) {
                    return;
                }
                setBellyBalance(Number(ethers.utils.formatUnits(res, 18)))
                setText(Number(ethers.utils.formatUnits(res, 18)));
            })
        }
    }

    const updateAccountInfo = async (address) => {
        const data = JSON.parse(await Account.getDetailReward(address));
        setTotalReward(data.data.total);
        setRewardLocked(data.data.totalRewardLocked);
        setRewardUnlock(data.data.totalRewardUnlock);
        const account = JSON.parse(await Account.getAccountByAddress(address));
        setAmountReward(account.totalRewardNotWithdraw);
        setBalanceOCoin(account.oCoin);
        setBalanceBCoin(account.bCoin);
    }

    const updateOriValue = async (address) => {
        const a = JSON.parse(await Account.getAccountByAddress(address));
        setOriValue(a.ori);
    }

    const getAccountDetail = async (accountAddress, listMerc) => {
        const a = await Account.getAccountByAddress(accountAddress);
        const data = JSON.parse(await Account.getDetailReward(accountAddress));
        setTotalReward(data.data.total);
        setRewardLocked(data.data.totalRewardLocked);
        setRewardUnlock(data.data.totalRewardUnlock);
        console.log(data);
        if (a) {
            const account = JSON.parse(a);
            setAccountId(account._id)
            if (account.ori >= 0) {
                setOriValue(account.ori);
            }
            setAmountReward(account.totalRewardNotWithdraw);
            setBalanceOCoin(account.oCoin);
            setBalanceBCoin(account.bCoin);
            await getMercSelected(account.mercSelected, listMerc)
        }
    }

    const getMercSelected = async (mercSelected, listMerc) => {
        if (listMerc.length > 0) {
            let arrMercSelected = [];
            for (let i = 0; i < mercSelected.length; i++) {
                const elm = mercSelected[i];
                const filter = listMerc.filter(e => e.number === Number(elm))
                if (filter[0]) {
                    arrMercSelected.push(filter[0]);
                }
            }
            setMercSelected(arrMercSelected);
            setText(text + 1);
        } else {
            setMercSelected([]);
            setText(text + 1);
        }
    }

    const changeSelectedList = (elm) => {
        const filter = mercSelected.filter(e => e.number === elm.number);
        if (mercSelected.length >= 4 || filter.length > 0) {
            return;
        }
        // console.log(mercSelected)
        mercSelected.push(elm);
        setMercSelected(mercSelected);
        // console.log(mercSelected);
        // console.log(mercSelected, 'bf');
        setText(elm.number);

    }

    const removeItemToSelect = (data) => {
        setMercSelected(mercSelected.filter(e => e.number !== data.number));
        // console.log(mercSelected, 'haha')
        setText(data.number + 0.2);
    }

    const updateStorage = () => {
        setStorage(localStorage.getItem('address'));
    }

    const removeStorage = () => {
        setStorage(null);
    }

    const checkStarRarity = (number) => {
        const startArr = [];
        for (let i = 0; i < number; i++) {
            startArr.push(i);
        }
        return startArr;
    }

    const changeLoading = () => {
        setLoading(true);
    }

    const removeLoading = () => {
        setLoading(false);
    }

    const updateNewMerc = (data) => {
        setNewMerc(data);
    }

    return (
        <DataContext.Provider
            value={{
                blanceBelly: bellyBalance,
                address: address,
                mercList: mercList,
                mercSelected: mercSelected,
                storage: storage,
                loading: loading,
                listEgg: listEgg,
                newMerc: newMerc,
                saveMercSlt: saveMercSlt,
                amountReward: amountReward,
                oriValue: oriValue,
                accountId: accountId,
                balanceOCoin: balanceOCoin,
                balanceBCoin: balanceBCoin,
                totalReward: totalReward,
                rewardLocked: rewardLocked,
                rewardUnlock: rewardUnlock,
                changeSelectedList: changeSelectedList,
                removeItemToSelect: removeItemToSelect,
                checkStarRarity: checkStarRarity,
                updateListMerc: getMercByAddress,
                updateMercInfo: updateMercInfo,
                getBalanceBelly: getBalanceBelly,
                updateStorage: updateStorage,
                removeStorage: removeStorage,
                updateAddress: updateAddress,
                changeLoading: changeLoading,
                removeLoading: removeLoading,
                getTotalEgg: getTotalEgg,
                updateNewMerc: updateNewMerc,
                updateAccountInfo: updateAccountInfo,
                updateOriValue: updateOriValue,
            }}
        >
            <Switch>
                {
                    routes.map((route, index) => (
                        <Route path={route.path} exact={route.exact} key={index}>
                            <route.component routes={route.routes}/>
                        </Route>
                    ))
                }
                <Redirect from="*" to="/home_page"/>
                <Redirect from="/" to="/home_page"/>
            </Switch>
            <Modal show={loading} id="commonMessageModal" centered size="lg">
                <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                    <div>
                        <p>Changing accounts…</p>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </DataContext.Provider>
    );
}

export default App;
