import {Modal} from "react-bootstrap";
import Account from '../../../share/account/account'
import DataContext from "../../../share/context/context";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import elmAll from '../../../assest/img/element-all.png';
import oriIcon from "../../../assest/img/icon-ori.svg";
import iconBelly from "../../../assest/img/icon-belly.svg"
import buttonUpgrade from '../../../assest/img/button-upgrade.png';
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import mystery from '../../../assest/img/mystery.png'
import upgradeFrame from '../../../assest/img/upgrade-frame.png'
import badgeTotal from '../../../assest/img/badge-total.png'
import {useEffect, useState} from "react";
import Header from "../../layout/app/header/Header";
import startSVG from "../../../assest/img/star.svg";
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import btc from "../../../assest/img/button-close.png";
import {Link} from "react-router-dom";
import Web3 from "web3";
import buttonTeamSettings from "../../../assest/img/button-team-settings.png";
import buttonUpgradeOne from "../../../assest/img/button-upgrade-1.png";
import {ethers} from "ethers";
import MerApi from "../../../share/mercenarie/mercenarie";


function Upgrade() {
    const [elementFilter, setElementFilter] = useState('all');
    const [rarityFilter, setRarityFilter] = useState('all');
    const [filterMer, setFilterMer] = useState(false);
    const [listMerFilter, setListMerFilter] = useState([]);
    const [mercIngredientFirst, setMercIngredientFirst] = useState(null);
    const [mercIngredientSecond, setMercIngredientSecond] = useState(null);
    const [newMer, setNewMer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const [textError, setTextError] = useState("");

    const changeMercShow = (e) => {
        if (!mercIngredientFirst) {
            setMercIngredientFirst(e);
            return;
        }
        if (!mercIngredientSecond) {
            if (e.number !== mercIngredientFirst.number) {
                setMercIngredientSecond(e);
            }
        }
    }

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return {totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1]}
    }

    const filter = (listMer, rarFl, elmFl) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        setListMerFilter(filterData);
    }

    const validateMer = (data) => {
        const checkFirstMerc = data.mercSelected.filter(e => e.number === mercIngredientFirst.number)
        const checkSecondMerc = data.mercSelected.filter(e => e.number === mercIngredientSecond.number)
        if (checkFirstMerc.length > 0 || checkSecondMerc.length > 0) {
            setShowModalConfirm(false);
            setTextError("Mercenarie is already in your team");
            setShowModalFail(true);
            return false;
        }
        return true;
    }

    const upgrade = async (data) => {
        if (validateMer(data)) {
            if (data.blanceBelly >= 200) {
                setShowModalConfirm(false);
                setLoading(true);
                const web3 = new Web3(Web3.givenProvider);
                const contractBelly = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BELLY_ABI), process.env.REACT_APP_BELLY_ADDRESS);
                await contractBelly.methods.transfer(
                    "0x23dFb47f22832aD8e9701F6C9743D2986c5D1CdA",
                    ethers.utils.parseUnits("200", 18)
                ).send({
                    from: data.address,
                }).on("receipt", async (receipt) => {
                    if (receipt.status) {
                        console.log(1)
                        const response = JSON.parse(await MerApi.upgrade(data.address, [mercIngredientFirst.number, mercIngredientSecond.number], receipt.transactionHash));
                        console.log(response);
                        if (response.status === "OK") {
                            await data.getBalanceBelly(data.address);
                            await data.updateMercInfo(data.address);
                            const newMerList = JSON.parse(await Account.getMercByAddress(data.address));
                            setNewMer(newMerList.filter(e => e.number === mercIngredientFirst.number)[0]);
                            setMercIngredientFirst(null);
                            setMercIngredientSecond(null);
                            setFilterMer(false);
                            setElementFilter('all');
                            setRarityFilter('all');
                            setLoading(false);
                            setShowModalFail(false);
                            setShowModalSuccess(true);
                        } else {
                            setLoading(false);
                            setShowModalFail(true);
                            setTextError(response.message);
                        }
                    } else {
                        setLoading(false);
                        setShowModalFail(true);
                        setTextError("An error occurred, please try again later");
                    }
                }).on("error", (error) => {
                    setLoading(false);
                    setShowModalFail(true);
                    setTextError("An error occurred, please try again later");
                })
            } else {
                setShowModalFail(true);
                setTextError("You don't have enough Belly");
            }
        }
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const closeModalFail = () => {
        setShowModalFail(false);
    }

    const closeModalSuccess = () => {
        setShowModalSuccess(false);
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="upgradePage">
                    <Header page={2} saveMerc={data.saveMercSlt}/>
                    <div id="content" className="col d-flex">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <Link to="/my_crew">
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col align-self-center">
                                <div className="upgradeMerc">
                                    <img src={upgradeFrame} className="badgeFrame"/>
                                    <div className="howto">Add 2 mercs with same grade to get a new upgrade Merc
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-between listMercUpgrade">
                                        <ul className="list-unstyled">
                                            <li>
                                                {
                                                    mercIngredientFirst ? (
                                                        <>
                                                            <img
                                                                src={
                                                                    mercIngredientFirst.element === "fire" ? fire :
                                                                        mercIngredientFirst.element === "earth" ? earth :
                                                                            mercIngredientFirst.element === "metal" ? metal :
                                                                                mercIngredientFirst.element === "water" ? water :
                                                                                    mercIngredientFirst.element === "wood" ? wood :
                                                                                        mercIngredientFirst.element === "dark" ? dark :
                                                                                            light
                                                                }
                                                                alt=""
                                                                className="element"
                                                            />
                                                            <div className="thumbMercWrap" onClick={() => {
                                                                setMercIngredientFirst(null);
                                                            }}>
                                                                <img
                                                                    className={
                                                                        mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                            mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                                                mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                                    mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                        mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                                                            mercIngredientFirst.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" : ""
                                                                    }
                                                                    src={mercIngredientFirst.avatar}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="thumbMercWrap"/>
                                                    )
                                                }
                                            </li>
                                            <li>
                                                {
                                                    mercIngredientSecond ? (
                                                        <>
                                                            <img
                                                                src={
                                                                    mercIngredientSecond.element === "fire" ? fire :
                                                                        mercIngredientSecond.element === "earth" ? earth :
                                                                            mercIngredientSecond.element === "metal" ? metal :
                                                                                mercIngredientSecond.element === "water" ? water :
                                                                                    mercIngredientSecond.element === "wood" ? wood :
                                                                                        mercIngredientSecond.element === "dark" ? dark :
                                                                                            light
                                                                }
                                                                alt=""
                                                                className="element"
                                                            />
                                                            <div className="thumbMercWrap" onClick={() => {
                                                                setMercIngredientSecond(null);
                                                            }}>
                                                                <img
                                                                    className={
                                                                        mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                            mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                                                mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                                    mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                        mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                                                            mercIngredientSecond.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" : ""
                                                                    }
                                                                    src={mercIngredientSecond.avatar}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="thumbMercWrap"/>
                                                    )
                                                }
                                            </li>
                                        </ul>
                                        <div className="mercMystery">
                                            <img src={mystery} alt=""/>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="col totalCost">
                                            <img src={badgeTotal} alt=""/>
                                            <div className="d-flex font-riverAdventurer mt-4">
                                                <div className="me-5">
                                                    200 <img src={iconBelly} width="30"/>
                                                </div>
                                                <div>
                                                    0 <img src={oriIcon} width="30"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto levelUp">
                                            <button className="btn" onClick={() => {
                                                setShowModalConfirm(true)
                                                // setTextError("The system is maintenance");
                                                // setShowModalFail(true);
                                            }}>
                                                <img src={buttonUpgrade}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="crewBoard">
                                    <div className="filterRarity d-flex justify-content-between">
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(data.mercList, 'all', elementFilter);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(data.mercList, 'common', elementFilter);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(data.mercList, 'rare', elementFilter);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(data.mercList, 'elite', elementFilter);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(data.mercList, 'epic', elementFilter);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(data.mercList, 'legendary', elementFilter);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(data.mercList, 'mythic', elementFilter);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(data.mercList, 'godly', elementFilter);
                                            }}
                                        >
                                            godly
                                        </div>
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(data.mercList, rarityFilter, 'all');
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(data.mercList, rarityFilter, 'light');
                                        }}/>
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(data.mercList, rarityFilter, 'water');
                                        }}/>
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(data.mercList, rarityFilter, 'metal');
                                        }}/>
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(data.mercList, rarityFilter, 'dark');
                                        }}/>
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(data.mercList, rarityFilter, 'fire');
                                        }}/>
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(data.mercList, rarityFilter, 'wood');
                                        }}/>
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(data.mercList, rarityFilter, 'earth');
                                        }}/>
                                    </div>
                                    <div className="listCrew mCustomScrollbar">
                                        {
                                            !filterMer ? (
                                                <div className="row g-0">
                                                    {
                                                        data.mercList.map((e, i) => (
                                                            <div
                                                                className="col-4"
                                                                key={i}
                                                                onClick={() => {
                                                                    changeMercShow(e);
                                                                }}
                                                            >
                                                                <div className="merc selected">
                                                                    <img
                                                                        src={
                                                                            e.element === "fire" ? fire :
                                                                                e.element === "earth" ? earth :
                                                                                    e.element === "metal" ? metal :
                                                                                        e.element === "water" ? water :
                                                                                            e.element === "wood" ? wood :
                                                                                                e.element === "dark" ? dark :
                                                                                                    light
                                                                        }
                                                                        alt=""
                                                                        className="element"
                                                                    />
                                                                    <div className="mercOverview">
                                                                        <div className="thumbMercWrap">
                                                                            <img
                                                                                className={
                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                }
                                                                                src={e.avatar}
                                                                            />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="star">
                                                                                {
                                                                                    data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                        <img src={startSVG} alt=""
                                                                                             key={idx}/>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            <div>Stamina: {e.stamina}/150</div>
                                                                            <div>XP: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                lv.{e.level}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className="row g-0">
                                                    {
                                                        listMerFilter.map((e, i) => (
                                                            <div
                                                                className="col-4"
                                                                key={i}
                                                                onClick={() => {
                                                                    changeMercShow(e);
                                                                }}
                                                            >
                                                                <div className="merc selected">
                                                                    <img
                                                                        src={
                                                                            e.element === "fire" ? fire :
                                                                                e.element === "earth" ? earth :
                                                                                    e.element === "metal" ? metal :
                                                                                        e.element === "water" ? water :
                                                                                            e.element === "wood" ? wood :
                                                                                                e.element === "dark" ? dark :
                                                                                                    light
                                                                        }
                                                                        alt=""
                                                                        className="element"
                                                                    />
                                                                    <div className="mercOverview">
                                                                        <div className="thumbMercWrap">
                                                                            <img
                                                                                className={
                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                }
                                                                                src={e.avatar}
                                                                            />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="star">
                                                                                {
                                                                                    data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                        <img src={startSVG} alt=""
                                                                                             key={idx}/>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            <div>Stamina: {e.stamina}/150</div>
                                                                            <div>XP: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                lv.{e.level}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={loading} id="commonMessageModal" size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Updating...</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalConfirm} id="upGradeModal" centered onHide={closeModalConfirm}>
                        <Modal.Body
                            className="commonMessageRibbonFrame d-flex align-items-center justify-content-center">
                            <div className="textHeading font-riverAdventurer">Fuse</div>
                            <img className="closeModal" src={btc} alt="" onClick={closeModalConfirm}/>
                            <img
                                src={buttonUpgradeOne}
                                className="buttonAction"
                                onClick={async () => {
                                    if (mercIngredientFirst && mercIngredientSecond) {
                                        if (mercIngredientFirst.rarityNumber === mercIngredientSecond.rarityNumber) {
                                           if (mercIngredientFirst.rarityNumber < 7) {
                                               await upgrade(data);
                                           } else {
                                               setShowModalConfirm(false);
                                               setShowModalFail(true);
                                               setTextError("Your merc is already at its highest star level");
                                           }
                                        } else {
                                            setShowModalConfirm(false);
                                            setShowModalFail(true);
                                            setTextError("Rarity of two ingredients must be the same.");
                                        }
                                    } else {
                                        setShowModalConfirm(false)
                                        setShowModalFail(true);
                                        setTextError("You need to choose 2 Merc to Upgrade.");
                                    }
                                }}
                            />
                            <div>
                                <div className="text">Get new merc cost</div>
                                <div className="d-flex font-riverAdventurer totalCost">
                                    <div className="">200 <img src={iconBelly} width="40"/></div>
                                    <div className="">
                                        0 <img src={oriIcon} width="40"/>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}
                           animation={false}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{textError}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalSuccess} id="upGradeSuccessModal" centered onHide={closeModalSuccess}>
                        <div className="modal-content align-items-center">
                            {
                                newMer ? (
                                    <div className="signSuccess">
                                        <div className="star">
                                            {
                                                data.checkStarRarity(newMer.rarityNumber).map((elm, idx) => (
                                                    <img src={startSVG} alt=""
                                                         key={idx}/>
                                                ))
                                            }
                                        </div>
                                        <img
                                            className={
                                                newMer ?
                                                    newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character s-char merc-broke" :
                                                        newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "character merc-cardinal" :
                                                            newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character s-char merc-cross" :
                                                                newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "character s-char merc-helsing" :
                                                                    newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "character merc-loogie" :
                                                                        newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "character s-char merc-monie" :
                                                                            newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "character merc-sharksan" :
                                                                                newMer.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "character s-char merc-slinger" : "" : ""
                                            }
                                            src={newMer.avatar}
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    <div className="signSuccess"/>
                                )
                            }
                            <Modal.Body className="messageStarFrame">
                                {
                                    newMer ? (
                                        <>
                                            <img className="closeModal" src={btc} alt="" onClick={closeModalSuccess}/>
                                            <div>Congratulation</div>
                                            <div>New {newMer.rarityNumber}* merc received</div>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Modal.Body>
                        </div>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Upgrade;
