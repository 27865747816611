import React from 'react';

class MarketApi {
    getAllItemSell = async (address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/getMercIsSell?address=${address}`).then(res => res.json());
    }

    getAllItemInMarket = async () => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/getAllMercInMarket`).then(res => res.json());
    }

    buyOriCoin = async (address, hash) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate/buyOri`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address,
                hash: hash
            })
        }).then(res => res.json());
    }

    buyLuckyCoin = async (address, hash) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate/buyLuckyCoin?address=${address}&hash=${hash}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address,
                hash: hash
            })
        }).then(res => res.json());
    }

    Buy = async (sellerAddress, buyAddress, number, signature, dataThatWasSigned) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/market/buy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sellerAddress: sellerAddress.toLowerCase(),
                buyAddress: buyAddress.toLowerCase(),
                number  : number,
                signature: signature.toString(),
                dataThatWasSigned: dataThatWasSigned.toString(),
            })
        }).then(res => res.text());
    }
}

export default new MarketApi();
