import bgFrameTop1 from '../../../assest/img/bg-frame-1-top.png'
import avatarSample from '../../../assest/img/avatar.png'
import tableDecor from '../../../assest/img/table-decor.png'
import tableDecor1 from '../../../assest/img/table-decor-1.png'
import tableDecor2 from '../../../assest/img/table-decor-2.png'
import torch from '../../../assest/img/torch.gif';
import badgeHistory from '../../../assest/img/battle-history-badge.png';
import bgFrame1 from '../../../assest/img/bg-frame-1-bottom.png';
import badgeWin from '../../../assest/img/badge-win.png';
import badgeLose from '../../../assest/img/badge-lose.png';
import badgeWinPer from '../../../assest/img/badge-win-percent.png';
import badgeLosePer from '../../../assest/img/badge-lose-percent.png';
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import DataContext from "../../../share/context/context";
import Header from "../../layout/app/header/Header";
import Account from "../../../share/account/account";
import {useEffect, useState} from "react";
import Moment from "moment";
import $ from "jquery";
import {Link} from "react-router-dom";


function HistoryBattle() {

    const [totalTransaction, setTotalTransaction] = useState([]);
    const [transactionsWin, setTransactionsWin] = useState([]);
    const [transactionsLose, setTransactionsLose] = useState([]);

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(async (accounts) => {
                if (accounts[0]) {
                    await getHistory(accounts[0]);
                }
            })
        }
    }, []);

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    const getHistory = async (address) => {
        const data = JSON.parse(await Account.getTransactionAttack(address));
        setTotalTransaction(data);
        const winTransactions = data.filter(e => e.status === 'win');
        setTransactionsWin(winTransactions);
        const loseTransactions = data.filter(e => e.status === 'loss')
        setTransactionsLose(loseTransactions);
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="battleHistoryPage">
                    <Header page={5} saveMerc={data.saveMercSlt}/>
                    <div id="content">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <Link to="/battle_field">
                                    <img src={leftArrowIcon} alt="" className="btnBack" />
                                </Link>
                            </div>
                            <div className="col">
                                <div className="tableWrap">
                                    <img src={badgeHistory} className="tableBadge"/>
                                    <img src={tableDecor} className="decor-top-left corner"/>
                                    <img src={tableDecor} className="decor-top-right corner"/>
                                    <img src={tableDecor} className="decor-bottom-left corner"/>
                                    <img src={tableDecor}
                                         className="decor-bottom-right corner"/>
                                    <img src={tableDecor1} className="decor-middle-left"/>
                                    <img src={tableDecor1}
                                         className="decor-middle-right"/>
                                    <img src={tableDecor2}
                                         className="decor-bottom-center"/>
                                    <img src={torch}
                                         className="decor-fire-left"/>
                                    <img src={torch}
                                         className="decor-fire-right"/>
                                    <div className="top">
                                        <img src={bgFrameTop1}
                                             alt=""/>
                                    </div>
                                    <div className="inner">
                                        <div className="overview d-flex align-items-center">
                                            <div className="col-auto">
                                                <img src={avatarSample} alt="" width="150"/>
                                            </div>
                                            <div
                                                className="col-auto d-flex align-items-center justify-content-center userId">
                                                <div className="item">
                                                    <div className="text">ID</div>
                                                    <div className="number">
                                                        {data.address.slice(0, 4) + '...' + data.address.slice(data.address.length - 4, data.address.length)}
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="text">Game</div>
                                                    <div
                                                        className="number">{data.storage !== null ? totalTransaction.length : 0}</div>
                                                </div>
                                            </div>
                                            <div className="col-auto p-2">
                                                <img src={badgeWin} className="badgeStatus"/>
                                                <div
                                                    className="number">{data.storage !== null ? transactionsWin.length : 0}</div>
                                                <div className="text">win</div>
                                            </div>
                                            <div className="col-auto p-2">
                                                <img src={badgeLose} className="badgeStatus"/>
                                                <div
                                                    className="number">{data.storage !== null ? transactionsLose.length : 0}</div>
                                                <div className="text">loss</div>
                                            </div>
                                            <div className="col-auto p-2">
                                                <img src={badgeWinPer} className="badgeStatus"/>
                                                <div className="number">
                                                    {data.storage !== null ? totalTransaction.length > 0 ? (transactionsWin.length * 100 / totalTransaction.length).toFixed(2) : 0 : 0}%
                                                </div>
                                                <div className="text">Winning rate</div>
                                            </div>
                                            <div className="col-auto p-2">
                                                <img src={badgeLosePer} className="badgeStatus"/>
                                                <div className="number">
                                                    {data.storage !== null ? totalTransaction.length > 0 ? (transactionsLose.length * 100 / totalTransaction.length).toFixed(2) : 0 : 0}%
                                                </div>
                                                <div className="text">Losing rate</div>
                                            </div>
                                        </div>
                                        <div className="tableHeader">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Status</th>
                                                    <th>Amount reward</th>
                                                    <th>Exp reward</th>
                                                    <th>Time attack</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="tableContent">
                                            {
                                                totalTransaction.length === 0 || data.storage === null ? (
                                                    <div className="noRecord">no record</div>
                                                ) : ("")
                                            }
                                            <div className="mCustomScrollbar">
                                                <table
                                                    className={totalTransaction.length === 0 || data.storage === null ? "table d-none" : "table"}>
                                                    <tbody>
                                                    {
                                                        totalTransaction.map((e, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <div
                                                                        className={e.status === "win" ? "status win" : "status loss"}
                                                                    >
                                                                        {e.status}
                                                                    </div>
                                                                </td>
                                                                <td>{e.amount_reward}</td>
                                                                <td>{e.exp_reward}</td>
                                                                <td>{Moment(e.time_attack).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <img src={bgFrame1} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default HistoryBattle;
