import { Modal } from "react-bootstrap";
import Account from '../../../share/account/account'
import DataContext from "../../../share/context/context";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import elmAll from '../../../assest/img/element-all.png';
import buttonAddTeam from '../../../assest/img/button-add-team.png';
import buttonRemoveTeam from '../../../assest/img/button-remove-team.png';
import buttonConfirmTeam from '../../../assest/img/button-confirm-team.png';
import buttonBattle from '../../../assest/img/button-battle-field.png';
import yourTeamFrame from '../../../assest/img/your-team-frame.png';
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import { useEffect, useState } from "react";
import Header from "../../layout/app/header/Header";
import startSVG from "../../../assest/img/star.svg";
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import btc from "../../../assest/img/button-close.png";
import { Link } from "react-router-dom";
import Web3 from "web3";


function MyCrew() {
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [filterMer, setFilterMer] = useState(false);
    const [rarityFilter, setRarityFilter] = useState('all');
    const [elementFilter, setElementFilter] = useState('all');
    const [listMerFilter, setListMerFilter] = useState([]);
    const [merShow, setMerShow] = useState(null);
    const [totalExp, setTotalExp] = useState(0);
    const [expLandmark, setExpLandmark] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const updateMercSelected = async (address, mercSelected, data, timeStamp, signature) => {
        const arrUpdate = []
        for (let i = 0; i < mercSelected.length; i++) {
            arrUpdate.push(mercSelected[i].number);
        }
        const resData = JSON.parse(await Account.updateTeamAttack(address, arrUpdate, timeStamp, signature));
        if (resData.status === "SUCCESS") {
            setLoading(false);
            setShowModalConfirm(true);
        } else if (resData.status === "ERR") {
            setLoading(false);
            setTitleAlert(resData.data.message);
            setShowModal(true);
            await data.updateMercInfo(address);
        }
    }

    const sign = async (address, mercSelected, data) => {
        if (window.ethereum && window.web3) {
            setLoading(true);
            setLoadingText('Updating...');
            const timeStamp = new Date().getTime();
            const web3 = new Web3(Web3.givenProvider);
            await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
                if (error) {
                    setLoading(false)
                    return;
                }
                await updateMercSelected(address,  mercSelected, data, timeStamp, signature);
            })
        }
    }

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    const closeModal = () => {
        setShowModal(false);
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const filter = (listMer, rarFl, elmFl) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        setListMerFilter(filterData);
    }

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return { totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1] }
    }

    const changeMercShow = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        setTotalExp(totalExpArr[e.level - 1])
        setExpLandmark(expLandmarkArr[e.level - 1])
        setMerShow(e);
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="teamSettingPage">
                    <Header page={2} saveMerc={data.saveMercSlt} />
                    <div id="content" className="col d-flex align-items-end">
                        <div
                            className="myCrewFrame d-flex"
                        >
                            <div className="col-auto">
                                <Link to="/my_crew">
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <div className="crewBoard">
                                    <div className="filterRarity d-flex justify-content-between">
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(data.mercList, 'all', elementFilter);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(data.mercList, 'common', elementFilter);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(data.mercList, 'rare', elementFilter);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(data.mercList, 'elite', elementFilter);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(data.mercList, 'epic', elementFilter);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(data.mercList, 'legendary', elementFilter);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(data.mercList, 'mythic', elementFilter);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(data.mercList, 'godly', elementFilter);
                                            }}
                                        >
                                            godly
                                        </div>
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(data.mercList, rarityFilter, 'all');
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(data.mercList, rarityFilter, 'light');
                                        }} />
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(data.mercList, rarityFilter, 'water');
                                        }} />
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(data.mercList, rarityFilter, 'metal');
                                        }} />
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(data.mercList, rarityFilter, 'dark');
                                        }} />
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(data.mercList, rarityFilter, 'fire');
                                        }} />
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(data.mercList, rarityFilter, 'wood');
                                        }} />
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(data.mercList, rarityFilter, 'earth');
                                        }} />
                                    </div>
                                    {
                                        data.storage !== null && data.mercList.length > 0 ? (
                                            <div className="listCrew mCustomScrollbar">
                                                {
                                                    !filterMer ? (
                                                        <div className="row g-0">
                                                            {
                                                                data.mercList.map((e, i) => (
                                                                    <div
                                                                        className="col-4"
                                                                        style={e.max_battle_over_one_day === 0 ? { opacity: 0.6 } : {}}
                                                                        key={i}
                                                                        onClick={() => {
                                                                            changeMercShow(e);
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={data.mercSelected.filter(em => em.number === e.number).length === 0 ? "merc" : "merc selected"}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    e.element === "fire" ? fire :
                                                                                        e.element === "earth" ? earth :
                                                                                            e.element === "metal" ? metal :
                                                                                                e.element === "water" ? water :
                                                                                                    e.element === "wood" ? wood :
                                                                                                        e.element === "dark" ? dark :
                                                                                                            light
                                                                                }
                                                                                alt=""
                                                                                className="element"
                                                                            />
                                                                            <img className="buttonAction" src={buttonAddTeam}
                                                                                onClick={() => {
                                                                                    if (data.mercSelected.length < 4) {
                                                                                        data.changeSelectedList(e);
                                                                                    } else {
                                                                                        setTitleAlert('Your crew is full! Please remove any Merc before adding another one!');
                                                                                        setShowModal(true);
                                                                                    }
                                                                                }} />
                                                                            <div className="mercOverview">
                                                                                <div className="thumbMercWrap">
                                                                                    <img
                                                                                        className={
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                        }
                                                                                        src={e.avatar}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <div className="star">
                                                                                        {
                                                                                            data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                                <img src={startSVG} alt=""
                                                                                                    key={i} />
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                    <div>Stamina: {e.stamina}/150</div>
                                                                                    <div>XP: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                        lv.{e.level}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="row g-0">
                                                            {
                                                                listMerFilter.map((e, i) => (
                                                                    <div className="col-4"
                                                                         key={i}
                                                                         style={e.max_battle_over_one_day === 0 ? { opacity: 0.6 } : {}}
                                                                         onClick={() => {
                                                                             changeMercShow(e);
                                                                         }}
                                                                    >
                                                                        <div
                                                                            className={data.mercSelected.filter(em => em.number === e.number).length === 0 ? "merc" : "merc selected"}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    e.element === "fire" ? fire :
                                                                                        e.element === "earth" ? earth :
                                                                                            e.element === "metal" ? metal :
                                                                                                e.element === "water" ? water :
                                                                                                    e.element === "wood" ? wood :
                                                                                                        e.element === "dark" ? dark :
                                                                                                            light
                                                                                }
                                                                                alt=""
                                                                                className="element"
                                                                            />
                                                                            <a href="#">
                                                                                <img className="buttonAction"
                                                                                    src={buttonAddTeam}
                                                                                    onClick={() => {
                                                                                        if (data.mercSelected.length < 4) {
                                                                                            data.changeSelectedList(e);
                                                                                        } else {
                                                                                            setTitleAlert('Your crew is full! Please remove any Merc before adding another one!');
                                                                                            setShowModal(true);
                                                                                        }
                                                                                    }} />
                                                                            </a>
                                                                            <div className="mercOverview">
                                                                                <div className="thumbMercWrap">
                                                                                    <img
                                                                                        className={
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                        }
                                                                                        src={e.avatar}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <div className="star">
                                                                                        {
                                                                                            data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                                <img src={startSVG} alt=""
                                                                                                    key={i} />
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                    <div>Stamina: {e.stamina}/150</div>
                                                                                    <div>XP: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                        lv.{e.level}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : ""
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="mercFullInfo">
                                    {
                                        merShow !== null ? (
                                            <>
                                                <div className="charId">
                                                    <img
                                                        src={
                                                            merShow.element === "fire" ? fire :
                                                                merShow.element === "earth" ? earth :
                                                                    merShow.element === "metal" ? metal :
                                                                        merShow.element === "water" ? water :
                                                                            merShow.element === "wood" ? wood :
                                                                                merShow.element === "dark" ? dark :
                                                                                    light
                                                        }
                                                        alt=""
                                                        className="element"
                                                    />
                                                    id: {merShow.number}
                                                </div>
                                                <div className="star">
                                                    {
                                                        data.checkStarRarity(merShow.rarityNumber).map((elm, i) => (
                                                            <img src={startSVG} alt=""
                                                                key={i} />
                                                        ))
                                                    }
                                                </div>
                                                <div className="mercPortrait">
                                                    <img
                                                        className={
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "merc-loogie" :
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "merc-sharksan" : ""
                                                        }
                                                        src={merShow.avatar}
                                                    />
                                                </div>
                                                <div className="charStamina">
                                                    <div className="d-flex justify-content-between text">
                                                        <div>stamina: {merShow.stamina}/150</div>
                                                        <div>{Math.floor(merShow.stamina * 100 / 150)}%</div>
                                                    </div>
                                                    <div className="processBar">
                                                        <div className="percent"
                                                            style={{ width: `${Math.floor(merShow.stamina * 100 / 150)}%` }} />
                                                    </div>
                                                </div>
                                                <div className="charXp mb-0">
                                                    <div className="d-flex justify-content-between text">
                                                        <div>Xp: {merShow.exp - expLandmark}/{totalExp} -
                                                            LV.{merShow.level}</div>
                                                        <div>{Math.floor((merShow.exp - expLandmark) * 100 / totalExp)}%</div>
                                                    </div>
                                                    <div className="processBar">
                                                        <div className="percent"
                                                            style={{ width: `${Math.floor((merShow.exp - expLandmark) * 100 / totalExp)}%` }} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col">
                                <div className="selectedTeam">
                                    <img className="imgMyTeam" src={yourTeamFrame} alt="" />
                                    <div className="listMerc">
                                        {
                                            data.storage !== null ? (
                                                <div className="row g-0">
                                                    {
                                                        data.mercSelected.map((e, i) => (
                                                            <div className="col-6" key={i}>
                                                                <div className="merc">
                                                                    <div className="selectedMercFrame">
                                                                        <div className="info">
                                                                            <img
                                                                                src={
                                                                                    e.element === "fire" ? fire :
                                                                                        e.element === "earth" ? earth :
                                                                                            e.element === "metal" ? metal :
                                                                                                e.element === "water" ? water :
                                                                                                    e.element === "wood" ? wood :
                                                                                                        e.element === "dark" ? dark :
                                                                                                            light
                                                                                }
                                                                                alt=""
                                                                                className="element"
                                                                            />
                                                                            <img src={buttonRemoveTeam} alt=""
                                                                                className="buttonRemove"
                                                                                onClick={() => {
                                                                                    data.removeItemToSelect(e);
                                                                                }}
                                                                            />
                                                                            <div className="selectedThumbMercWrap">
                                                                                <img src={e.avatar} alt="" />
                                                                            </div>
                                                                            <div className="star">
                                                                                {
                                                                                    data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                        <img src={startSVG} alt=""
                                                                                            key={i} />
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        data.mercSelected.length < 1 ? (
                                                            <div className="col-6">
                                                                <div className="merc">
                                                                    <div className="selectedMercFrame">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }
                                                    {
                                                        data.mercSelected.length < 2 ? (
                                                            <div className="col-6">
                                                                <div className="merc">
                                                                    <div className="selectedMercFrame">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }
                                                    {
                                                        data.mercSelected.length < 3 ? (
                                                            <div className="col-6">
                                                                <div className="merc">
                                                                    <div className="selectedMercFrame">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }
                                                    {
                                                        data.mercSelected.length < 4 ? (
                                                            <div className="col-6">
                                                                <div className="merc">
                                                                    <div className="selectedMercFrame">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }
                                                </div>
                                            ) : (
                                                <div className="row g-0">
                                                    <div className="col-6">
                                                        <div className="merc">
                                                            <div className="selectedMercFrame">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="merc">
                                                            <div className="selectedMercFrame">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="merc">
                                                            <div className="selectedMercFrame">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="merc">
                                                            <div className="selectedMercFrame">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <img className="buttonConfirmTeam" src={buttonConfirmTeam}
                                    onClick={async () => {
                                        await sign(data.address, data.mercSelected, data);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Modal show={showModalConfirm} id="confirmSelectedTeamModal" centered size="lg"
                        onHide={closeModalConfirm}>
                        <Modal.Body className="confirmSelectedTeamFrame d-flex align-items-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalConfirm} />
                            <Link to="battle_field" className="goToBattleField">
                                <img src={buttonBattle} alt="" />
                            </Link>
                            <div className="w-100">
                                <div className="selectedTeam">
                                    <div className="listMerc">
                                        <div className="row g-0 justify-content-center">
                                            {
                                                data.mercSelected.map((e, i) => (
                                                    <div className="col-3" key={i}>
                                                        <div className="merc">
                                                            <img
                                                                src={
                                                                    e.element === "fire" ? fire :
                                                                        e.element === "earth" ? earth :
                                                                            e.element === "metal" ? metal :
                                                                                e.element === "water" ? water :
                                                                                    e.element === "wood" ? wood :
                                                                                        e.element === "dark" ? dark :
                                                                                            light
                                                                }
                                                                alt=""
                                                                className="element"
                                                            />
                                                            <div className="selectedMercFrame">
                                                                <div className="selectedThumbMercWrap">
                                                                    <img src={e.avatar} alt="" />
                                                                </div>
                                                                <div className="star">
                                                                    {
                                                                        data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                            <img src={startSVG} alt=""
                                                                                key={i} />
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>Your crew has been set! <br />Time to hunt down some criminal</div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModal} id="commonMessageModal" centered size="lg" onHide={closeModal}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModal} />
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={loading} id="commonMessageModal" size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>{loadingText}…</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default MyCrew;
