import React from 'react';

class Mercenarie {
    attack = async (number, difficulty, address, dataThatWasSigned, signature) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/attack?number=${number}&difficulty=${difficulty}&address=${address.toLowerCase()}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`)
            .then(res => res.text())
    }

    speedAttack = async (address, difficulty, dataThatWasSigned, signature) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/speedBattle?difficulty=${difficulty}&address=${address.toLowerCase()}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`)
            .then(res => res.text())
    }

    getDetailMerc = async (number) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/getByTokenId?number=${number}`).then(res => res.text())
    }

    getMercInArr = async (transactionHash, address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/getMercInNumArr?transactionHash=${transactionHash}&address=${address}`)
            .then(res => res.text())
    }

    getHitsOfMercySelected = async (number) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/getHitsOfMerc?number=${number}`)
            .then(res => res.text())
    }

    levelup = async(address, merNumber, hash) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/levelup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address,
                merNumber,
                hash
            })
        }).then(res => res.text())
    }

    upgrade = async(address, listMer, hash) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/startUp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address,
                listMer,
                hash
            })
        }).then(res => res.text())
    }

    detached = async (address, merNumber, type, signature, dataThatWasSigned) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie/detached`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address,
                merNumber,
                type,
                signature: signature.toString(),
                dataThatWasSigned: dataThatWasSigned.toString(),
            })
        }).then(res => res.text())
    }
}

export default new Mercenarie();
