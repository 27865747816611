import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import star from '../../../assest/img/star.svg'
import Header from "../../layout/app/header/Header";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import bellyIcon2 from '../../../assest/img/icon-belly.svg'
import elmAll from '../../../assest/img/element-all.png';
import paperBoard from '../../../assest/img/paper-board.png';
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import {useEffect, useState} from "react";
import MarketApi from '../../../share/market/market';
import Web3 from "web3";
import buttonReturn from "../../../assest/img/button-return.png";
import DataContext from "../../../share/context/context";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import {useLocation} from "react-router-dom"
import {Link} from "react-router-dom";
import oriIcon from "../../../assest/img/icon-ori.svg";
import bcm from "../../../assest/img/button-close-metal.png";
import badgeMerc from "../../../assest/img/badge-merc.png";
import torch from "../../../assest/img/torch.gif";
import bob from "../../../assest/img/button-orange-blank.png";


function MyShop() {
    const [allItems, setAllItems] = useState([]);
    const [allMercSell, setMercSell] = useState([]);
    const [filterMer, setFilterMer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const [listMerFilter, setListMerFilter] = useState([]);
    const [rarityFilter, setRarityFilter] = useState('all');
    const [titleAlert, setTitleAlert] = useState('');
    const [elementFilter, setElementFilter] = useState('all');
    const [listItemMarketFilter, setListItemMarketFilter] = useState([]);
    const [merReturn, setMerReturn] = useState({});
    const [itemMarketReturn, setItemMarketReturn] = useState({});
    const web3 = new Web3(Web3.givenProvider);
    const contractMarket = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MARKET_ABI), process.env.REACT_APP_MARKET_ADDRESS);


    const location = useLocation()
    const marketItem = location.state?.marketItem

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(async (accounts) => {
                if (accounts[0]){
                    await getAllItems(accounts[0]);
                }
            })
        }
    }, [])

    useEffect(() => {
        if (window.ethereum && window.web3) {
            window.ethereum.on('accountsChanged', async (accounts) => {
                if (accounts.length > 0) {
                    await getAllItems(accounts[0]);
                }
            });
        }
    }, [])

    const numberFormat = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const getAllItems = async (address) => {
        const data = await MarketApi.getAllItemSell(address);
        setMercSell(data.data.mercArr);
        setAllItems(data.data.priceMercArr);
    }

    const closeModalSuccess = () => {
        setShowModalSuccess(false);
    }

    const closeModalReturn = () => {
        setShowModal(false);
    }

    const closeModalFail = () => {
        setShowModalFail(false);
    }

    const showModalReturn = (mer, itemMarket) => {
        setShowModal(true);
        setMerReturn(mer);
        setItemMarketReturn(itemMarket);
    }

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return {totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1]}
    }

    const unStakeMerc = async (tokenId, address, data) => {
        await contractMarket.methods.unstakeNft(tokenId).send({
            from: address
        }).on('receipt', async (receipt) => {
            if (receipt.status){
                await data.updateMercInfo(address);
                await getAllItems(address);
                setRarityFilter('all');
                setElementFilter('all');
                setShowModalLoading(false);
                setShowModalSuccess(true);
                setShowModal(false);
            }
        }).on('error', (error) => {
            setShowModalLoading(false);
            setTitleAlert("Un sell Merc fail");
            setShowModalFail(true);
        })
    }

    const returnMer = async (number, address, data) => {
        setShowModalLoading(true);
        if (address) {
            await unStakeMerc(number, address, data);
        } else {
            setTitleAlert('No wallet connect.');
            setShowModalLoading(false);
            setShowModalFail(true);
        }
    }

    const filter = (listMer, rarFl, elmFl, itemsMarket) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        const itemsMarketFilter = [];
        for (let i = 0; i < filterData.length; i++) {
            const filter = itemsMarket.filter(e => e.number === filterData[i].number);
            if (filter.length > 0) {
                itemsMarketFilter.push(filter[0]);
            }
        }
        setListItemMarketFilter(itemsMarketFilter);
        setListMerFilter(filterData);
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="myshopPage">
                    <Header page={4} saveMerc={true}/>
                    <div id="content" className="col d-flex align-items-end">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <Link to='/market'>
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col d-flex justify-content-center">
                                <div className="crewBoard metalFrame">
                                    <div className="filterRarity d-flex align-items-center">
                                        <div className="pageName d-flex	align-items-center me-auto">
                                            <img src={paperBoard} alt=""/>
                                            <span className="text font-riverAdventurer">my shop</span>
                                        </div>
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(allMercSell, 'all', elementFilter, allItems);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(allMercSell, 'common', elementFilter, allItems);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(allMercSell, 'rare', elementFilter, allItems);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(allMercSell, 'elite', elementFilter, allItems);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(allMercSell, 'epic', elementFilter, allItems);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(allMercSell, 'legendary', elementFilter, allItems);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(allMercSell, 'mythic', elementFilter, allItems);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(allMercSell, 'godly', elementFilter, allItems);
                                            }}
                                        >
                                            godly
                                        </div>
                                        <img src={bellyIcon2} className="symbol" alt=""/>
                                        <img src={oriIcon} className="symbol" alt=""/>
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(allMercSell, rarityFilter, 'all', allItems);
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(allMercSell, rarityFilter, 'light', allItems);
                                        }}/>
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(allMercSell, rarityFilter, 'water', allItems);
                                        }}/>
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(allMercSell, rarityFilter, 'metal', allItems);
                                        }}/>
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(allMercSell, rarityFilter, 'dark', allItems);
                                        }}/>
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(allMercSell, rarityFilter, 'fire', allItems);
                                        }}/>
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(allMercSell, rarityFilter, 'wood', allItems);
                                        }}/>
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(allMercSell, rarityFilter, 'earth', allItems);
                                        }}/>
                                    </div>
                                    {
                                        data.storage !== null ? (
                                            <div className="listCrew mCustomScrollbar">
                                                {
                                                    !filterMer ? (
                                                        <div className="row g-0 mt-4">
                                                            {allMercSell.map((e, i) => (
                                                                <div className="col-2" key={i}>
                                                                    <div className="merc selected">
                                                                        <img src={e.element === "fire" ? fire :
                                                                            e.element === "earth" ? earth :
                                                                                e.element === "metal" ? metal :
                                                                                    e.element === "water" ? water :
                                                                                        e.element === "wood" ? wood :
                                                                                            e.element === "dark" ? dark :
                                                                                                light} alt=""
                                                                             className="element"/>
                                                                        <img
                                                                            className="buttonAction"
                                                                            src={buttonReturn}
                                                                            alt=""
                                                                            onClick={() => {
                                                                                showModalReturn(e, allItems.filter(element => element.number === e.number)[0]);
                                                                            }}
                                                                        />
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                            <img src={star} alt=""
                                                                                                 key={idx}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        allItems.filter(element => element.number === e.number).length > 0 ?
                                                                                            `${numberFormat(allItems.filter(element => element.number === e.number)[0].price)} ` :
                                                                                            0
                                                                                    }
                                                                                    Belly</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="row g-0 mt-4">
                                                            {listMerFilter.map((e, i) => (
                                                                <div className="col-2" key={i}>
                                                                    <div className="merc selected">
                                                                        <img src={e.element === "fire" ? fire :
                                                                            e.element === "earth" ? earth :
                                                                                e.element === "metal" ? metal :
                                                                                    e.element === "water" ? water :
                                                                                        e.element === "wood" ? wood :
                                                                                            e.element === "dark" ? dark :
                                                                                                light} alt=""
                                                                             className="element"/>
                                                                        <img
                                                                            className="buttonAction"
                                                                            src={buttonReturn}
                                                                            alt=""
                                                                            onClick={() => {
                                                                                showModalReturn(e, allItems.filter(element => element.number === e.number)[0]);
                                                                            }}
                                                                        />
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                            <img src={star} alt=""
                                                                                                 key={idx}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        listItemMarketFilter.filter(element => element.number === e.number).length > 0 ?
                                                                                            `${numberFormat(listItemMarketFilter.filter(element => element.number === e.number)[0].price)} ` :
                                                                                            0
                                                                                    }
                                                                                    Belly
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div className="listCrew mCustomScrollbar">
                                                <div className="row g-0 mt-4">

                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModal} id="returnMercModal" centered size="lg" onHide={closeModalReturn}>
                        <Modal.Body
                            className="mercFrameMetal d-flex flex-column align-items-center justify-content-center">
                            {merReturn.number >= 0 ? (
                                <>
                                    <img className="closeModal" src={bcm} alt="" onClick={closeModalReturn}/>
                                    <img src={badgeMerc} className="badgeFrame"/>
                                    <div className="mercFullInfo d-flex justify-content-center align-items-center">
                                        <div className="col-auto mercPortrait">
                                            <img
                                                className={
                                                    merReturn ?
                                                        merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "s-char merc-broke" :
                                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                                merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "s-char merc-cross" :
                                                                    merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                        merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "merc-loogie" :
                                                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "s-char merc-monie" :
                                                                                merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "merc-sharksan" :
                                                                                    merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "s-char merc-slinger" : "" :""
                                                }
                                                src={merReturn.avatar}
                                                alt="" width="250"
                                            />
                                        </div>
                                        <div className="info">
                                            <div className="charId">
                                                <img
                                                    src={
                                                        merReturn.element === "fire" ? fire :
                                                            merReturn.element === "earth" ? earth :
                                                                merReturn.element === "metal" ? metal :
                                                                    merReturn.element === "water" ? water :
                                                                        merReturn.element === "wood" ? wood :
                                                                            merReturn.element === "dark" ? dark :
                                                                                light
                                                    }
                                                    alt=""
                                                    className="element"
                                                />
                                                id: {merReturn.number}
                                            </div>
                                            <div className="star my-4">
                                                {
                                                    data.checkStarRarity(merReturn.rarityNumber).map((elm, idx) => (
                                                        <img src={star} alt=""
                                                             key={idx}/>
                                                    ))
                                                }
                                            </div>
                                            <div className="charStamina mb-4">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div>stamina: {merReturn.stamina}/150</div>
                                                    <div>{Math.floor(merReturn.stamina * 100 / 150)}%</div>
                                                </div>
                                                <div className="processBar">
                                                    <div className="percent"
                                                         style={{width: `${Math.floor(merReturn.stamina * 100 / 150)}%`}}/>
                                                </div>
                                            </div>
                                            <div className="charXp mb-0">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div>
                                                        Xp: {merReturn.exp - calculationToalExpAndLandmark(merReturn).expLandmark}/{calculationToalExpAndLandmark(merReturn).totalExp}
                                                        - LV.{merReturn.level}
                                                    </div>
                                                    <div>{Math.floor((merReturn.exp - calculationToalExpAndLandmark(merReturn).expLandmark) * 100 / calculationToalExpAndLandmark(merReturn).totalExp)}%</div>
                                                </div>
                                                <div className="processBar">
                                                    <div className="percent"
                                                         style=
                                                             {{
                                                                 width:
                                                                     `${Math.floor((merReturn.exp - calculationToalExpAndLandmark(merReturn).expLandmark) * 100 / calculationToalExpAndLandmark(merReturn).totalExp)}%`
                                                             }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sellingPrice">
                                                Selling: {numberFormat(itemMarketReturn.price)}
                                                <img src={bellyIcon2} className="symbol"/>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="buttonText" onClick={async () => {
                                        await returnMer(merReturn.number, data.address, data);
                                    }}>
                                        <img src={bob} alt=""/>
                                        <span className="text">Return</span>
                                    </button>
                                    <img className="torch" src={torch} alt=""/>
                                    <img className="torch right" src={torch} alt=""/>
                                </>
                            ) : null}
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalSuccess} id="buyMercSuccessModal" centered onHide={closeModalSuccess}>
                        <div className="modal-content align-items-center">
                            <div className="signSuccess">
                                <img
                                    className={
                                        merReturn ?
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character s-char merc-broke" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "character merc-cardinal" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character s-char merc-cross" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "character s-char merc-helsing" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "character merc-loogie" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "character s-char merc-monie" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "character merc-sharksan" :
                                            merReturn.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "character s-char merc-slinger" : "" :""
                                    }
                                    src={merReturn.avatar}
                                    alt=""
                                />
                            </div>
                            <Modal.Body className="messageStarFrameMetal d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={bcm} alt="" data-bs-dismiss="modal" onClick={closeModalSuccess}/>
                                Purchase successfully Merc ID {merReturn.number}.
                            </Modal.Body>
                        </div>
                    </Modal>
                    <Modal show={showModalLoading} id="commonMessageModal" centered size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Pending…</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default MyShop;
