import badgeFow from '../../../assest/img/badge-fow.png'
import iconOCoin from '../../../assest/img/icon-o-coin.svg'
import iconBCoin from '../../../assest/img/icon-b-coin.svg'
import iconPlus from '../../../assest/img/icon-plus.png'
import lifeWish from '../../../assest/img/life-wish.png'
import buttonWish from '../../../assest/img/button-wish.png'
import buttonMakeAllWish from '../../../assest/img/button-make-all-wishes.png'
import moneyWish from '../../../assest/img/money-wish.png'
import careerWish from '../../../assest/img/career-wish.png'
import loveWish from '../../../assest/img/love-wish.png'
import bgTreasure from '../../../assest/img/bg-treasure-1.png'
import badgeStart from '../../../assest/img/badge-star.png'
import buttonClaimReward from '../../../assest/img/button-claim-reward.png'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import DataContext from "../../../share/context/context";
import Header from "../../layout/app/header/Header";
import Account from "../../../share/account/account";
import {useEffect, useState} from "react";
import Moment from "moment";
import $ from "jquery";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import Web3 from "web3";
import {Link} from "react-router-dom";


function Fow() {
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [textError, setTextError] = useState("");
    const [reward, setReward] = useState("");
    const web3 = new Web3(Web3.givenProvider);

    const validateData = (data, type) => {
        if (type === 'o') {
            if (!data.balanceOCoin || data.balanceOCoin <= 0) {
                setShowModalError(true);
                setTextError("You don't have enough OCoin");
                return false;
            }
            return true;
        }
        if (type === 'b') {
            if (!data.balanceBCoin || data.balanceBCoin <= 0) {
                setShowModalError(true);
                setTextError("You don't have enough BCoin");
                return false;
            }
            return true;
        }
    }

    const signData = async (address, dataContext, type) => {
        if (validateData(dataContext, type)) {
            setShowModalLoading(true);
            const timeStamp = new Date().getTime();
            await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
                if (error) {
                    setShowModalLoading(false);
                    setShowModalError(true);
                    setTextError("Something went wrong");
                    return;
                }
                console.log(timeStamp, signature)
                if (type === 'o') {
                    await openOc(address, dataContext, timeStamp, signature);
                }
                if (type === 'b') {
                    await openBc(address, dataContext, timeStamp, signature);
                }
            })
        }
    }

    const openOc = async (address, dataContext, dataThatWasSigned, signature) => {
        const responseData = JSON.parse(await Account.openOc(address, dataThatWasSigned, signature));
        console.log(responseData);
        if (responseData.status === "OK") {
            await dataContext.getBalanceBelly(address);
            await dataContext.updateOriValue(address);
            await dataContext.updateAccountInfo(address);
            setShowModalLoading(false);
            setShowModalSuccess(true)
            setReward("You received " + responseData.data.oriValue + " ORI");
        } else {
            setShowModalLoading(false);
            setShowModalError(true);
            setTextError(responseData.message);
        }
    }

    const openBc = async (address, dataContext, dataThatWasSigned, signature) => {
        const responseData = JSON.parse(await Account.openBc(address, dataThatWasSigned, signature));
        if (responseData.status === "OK") {
            await dataContext.getBalanceBelly(address);
            await dataContext.updateOriValue(address);
            await dataContext.updateAccountInfo(address);
            setShowModalLoading(false);
            setShowModalSuccess(true)
            setReward(responseData.message);
        } else {
            setShowModalLoading(false);
            setShowModalError(true);
            setTextError(responseData.message);
        }
    }
    const closeModalSuccess = () => setShowModalSuccess(false);

    const closeModalError = () => setShowModalError(false);

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="fowPage">
                    <img src={bgTreasure} className="fowPageFooterImg"/>
                    <Header page={6} saveMerc={data.saveMercSlt}/>
                    <div id="content">
                        <div className="fowFrame">
                            <div className="fowFrameTop d-flex align-items-center">
                                <div><img src={badgeFow} width="240"/></div>
                                <div className="balance d-flex">
                                    <div className="item mt-0">
                                        <img src={iconOCoin} className="symbol"/>
                                        <Link to="/tarven_shop" className="buyMore">
                                            <img src={iconPlus}/>
                                        </Link>
                                        <div className="text font-riverAdventurer">O-Coin</div>
                                        <div className="number font-riverAdventurer">
                                            {
                                                data.balanceOCoin ? data.balanceOCoin : 0
                                            }
                                        </div>
                                    </div>
                                    <div className="item mt-0">
                                        <img src={iconBCoin} className="symbol"/>
                                        <Link to="/tarven_shop" className="buyMore">
                                            <img src={iconPlus}/>
                                        </Link>
                                        <div className="text font-riverAdventurer">B-Coin</div>
                                        <div className="number font-riverAdventurer">
                                            {
                                                data.balanceBCoin ? data.balanceBCoin : 0
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row h-100 wishList">
                                <div className="col-md-3">
                                    <div className="item d-flex flex-column">
                                        <h5 className="col-auto title font-riverAdventurer">Life wish</h5>
                                        <div className="icon"><img src={lifeWish} alt=""/></div>
                                        <div className="col">
                                            <p>Looking for some Ori? Drop your O-Coin here!</p>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn"
                                                onClick={async () => {
                                                    await signData(data.address, data, 'o');
                                                }}
                                            >
                                                <img src={buttonWish} width="85"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="item d-flex flex-column">
                                        <h5 className="col-auto title font-riverAdventurer">Money wish</h5>
                                        <div className="icon"><img src={moneyWish} alt=""/></div>
                                        <div className="col">
                                            <p>Goodluck with B-Coin! I will make you rich!</p>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn"
                                                onClick={async () => {
                                                    await signData(data.address, data, 'b');
                                                }}
                                            >
                                                <img src={buttonWish} width="85"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 comingSoon">
                                    <div className="item d-flex flex-column">
                                        <h5 className="col-auto title font-riverAdventurer">Career wish</h5>
                                        <div className="icon"><img src={careerWish} alt=""/></div>
                                        <div className="col">
                                            <p>Coming Soon</p>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn">
                                                <img src={buttonWish} width="85"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 comingSoon">
                                    <div className="item d-flex flex-column">
                                        <h5 className="col-auto title font-riverAdventurer">Love wish</h5>
                                        <div className="icon">
                                            <img src={loveWish} alt=""/>
                                        </div>
                                        <div className="col">
                                            <p>Coming Soon</p>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn">
                                                <img src={buttonWish} width="85"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<button className="btn makeAllWishes">*/}
                            {/*    <img src={buttonMakeAllWish} width="190"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <Modal show={showModalSuccess} id="claimFowModal" fullscreen onHide={closeModalSuccess}>
                        <Modal.Body className="d-flex align-items-center justify-content-center">
                            <div className="frame d-flex flex-column align-items-center justify-content-center">
                                <div className="top">
                                    <img src={badgeStart} alt="" width="250"/>
                                    <h5 className="font-riverAdventurer text-white">Your reward</h5>
                                </div>
                                <div>{reward}</div>
                                <button className="btn p-0 buttonClaimReward">
                                    <img src={buttonClaimReward} width="170" onClick={closeModalSuccess}/>
                                </button>
                            </div>
                            <img src={bgTreasure} className="modalFooterImg"/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalLoading} id="commonMessageModal" size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Wishing...</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalError} id="commonMessageModal" centered size="lg" onHide={closeModalError}
                           animation={false}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalError}/>
                            <div>
                                <p>{textError}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Fow;
