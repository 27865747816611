import DataContext from "../../../share/context/context";
import sellInBellyButton from '../../../assest/img/button-sell-in-belly.png'
import sellInBnbButton from '../../../assest/img/button-sell-in-bnb.png'
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import badgeMerc from "../../../assest/img/badge-merc.png";
import Header from "../../layout/app/header/Header";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import elmAll from '../../../assest/img/element-all.png';
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import {useEffect, useState} from "react";
import startSVG from "../../../assest/img/star.svg";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import {Link} from "react-router-dom";
import torch from "../../../assest/img/torch.gif";

function SellMerc() {
    const [rarityFilter, setRarityFilter] = useState('all');
    const [filterMer, setFilterMer] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const [elementFilter, setElementFilter] = useState('all');
    const [listMerFilter, setListMerFilter] = useState([]);
    const [mercSelected, setMercSelected] = useState({});
    const [numberSelected, setNumberSelected] = useState(null);
    const [titleAlert, setTitleAlert] = useState('');

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    const selectMerc = (mer) => {
        setMercSelected(mer);
        setNumberSelected(mer.number);
    }

    const closeModalFail = () => {
        setShowModalFail(false)
    }

    const filter = (listMer, rarFl, elmFl) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        setListMerFilter(filterData);
    }

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return {totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1]}
    }

    const numberFormat = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="sellMercPage">
                    <Header page={4} saveMerc={true}/>
                    <div id="content" className="col d-flex">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <Link to="/market">
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col d-flex justify-content-center">
                                <div className="crewBoard stoneFrame">
                                    <div className="filterRarity d-flex">
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(data.mercList, 'all', elementFilter);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(data.mercList, 'common', elementFilter);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(data.mercList, 'rare', elementFilter);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(data.mercList, 'elite', elementFilter);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(data.mercList, 'epic', elementFilter);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(data.mercList, 'legendary', elementFilter);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(data.mercList, 'mythic', elementFilter);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(data.mercList, 'godly', elementFilter);
                                            }}
                                        >
                                            godly
                                        </div>
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(data.mercList, rarityFilter, 'all');
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(data.mercList, rarityFilter, 'light');
                                        }}/>
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(data.mercList, rarityFilter, 'water');
                                        }}/>
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(data.mercList, rarityFilter, 'metal');
                                        }}/>
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(data.mercList, rarityFilter, 'dark');
                                        }}/>
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(data.mercList, rarityFilter, 'fire');
                                        }}/>
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(data.mercList, rarityFilter, 'wood');
                                        }}/>
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(data.mercList, rarityFilter, 'earth');
                                        }}/>
                                    </div>
                                    {
                                        data.storage !== null ? (
                                            <div className="listCrew mCustomScrollbar">
                                                {
                                                    !filterMer ? (
                                                        <div className="row g-0">
                                                            {data.mercList.map((e, i) => (
                                                                <div
                                                                    className="col-4"
                                                                    onClick={() => {
                                                                        selectMerc(e)
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    <div
                                                                        className={numberSelected !== null && numberSelected === e.number ? "merc selected" : 'merc'}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                e.element === "fire" ? fire :
                                                                                    e.element === "earth" ? earth :
                                                                                        e.element === "metal" ? metal :
                                                                                            e.element === "water" ? water :
                                                                                                e.element === "wood" ? wood :
                                                                                                    e.element === "dark" ? dark :
                                                                                                        light
                                                                            }
                                                                            alt=""
                                                                            className="element"
                                                                        />
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                            <img src={startSVG} alt=""
                                                                                                 className="mCS_img_loaded" key={i}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>Stamina: {e.stamina}/150</div>
                                                                                <div>Xp: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                    LV.{e.level}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="row g-0">
                                                            {listMerFilter.map((e, i) => (
                                                                <div
                                                                    className="col-4"
                                                                    onClick={() => {
                                                                        selectMerc(e)
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    <div
                                                                        className={numberSelected !== null && numberSelected === e.number ? "merc selected" : 'merc'}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                e.element === "fire" ? fire :
                                                                                    e.element === "earth" ? earth :
                                                                                        e.element === "metal" ? metal :
                                                                                            e.element === "water" ? water :
                                                                                                e.element === "wood" ? wood :
                                                                                                    e.element === "dark" ? dark :
                                                                                                        light
                                                                            }
                                                                            alt=""
                                                                            className="element"
                                                                        />
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                                            <img src={startSVG} alt=""
                                                                                                 className="mCS_img_loaded" key={i}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>Stamina: {e.stamina}/150</div>
                                                                                <div>Xp: {e.exp - calculationToalExpAndLandmark(e).expLandmark}/{calculationToalExpAndLandmark(e).totalExp} -
                                                                                    LV.{e.level}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div className="listCrew mCustomScrollbar">
                                                <div className="row g-0">
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-auto ms-auto">
                                <div
                                    className="mercFrameMetal d-flex flex-column align-items-center justify-content-center">
                                    <img src={badgeMerc} className="badgeFrame"/>
                                    {
                                        mercSelected.number >= 0 ? (
                                            <div className="mercFullInfo d-flex justify-content-center align-items-center">
                                                <div className="col-auto mercPortrait">
                                                    <img
                                                        className={
                                                            mercSelected ?
                                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "s-char merc-broke" :
                                                                    mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                                        mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "s-char merc-cross" :
                                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "merc-loogie" :
                                                                                    mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "s-char merc-monie" :
                                                                                        mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "merc-sharksan" :
                                                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "s-char merc-slinger" : "" :""
                                                        }
                                                        src={mercSelected.avatar}
                                                        alt=""
                                                        width="250"
                                                    />
                                                </div>
                                                <div className="info">
                                                    <div className="charId">
                                                        <img src={
                                                            mercSelected.element === "fire" ? fire :
                                                                mercSelected.element === "earth" ? earth :
                                                                    mercSelected.element === "metal" ? metal :
                                                                        mercSelected.element === "water" ? water :
                                                                            mercSelected.element === "wood" ? wood :
                                                                                mercSelected.element === "dark" ? dark :
                                                                                    light
                                                        } alt="" className="element"/>
                                                        id: {mercSelected.number}
                                                    </div>
                                                    <div className="star my-4"  >
                                                        {
                                                            data.checkStarRarity(mercSelected.rarityNumber).map((elm, i) => (
                                                                <img src={startSVG} alt=""
                                                                     key={i}/>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="charStamina mb-4">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>stamina: {mercSelected.stamina}/150</div>
                                                            <div>{Math.floor(mercSelected.stamina * 100 / 150)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor(mercSelected.stamina * 100 / 150)}%`}}/>
                                                        </div>
                                                    </div>
                                                    <div className="charXp mb-0">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>Xp: {mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark}/{calculationToalExpAndLandmark(mercSelected).totalExp} -
                                                                LV.{mercSelected.level}</div>
                                                            <div>{Math.floor((mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark) * 100 / calculationToalExpAndLandmark(mercSelected).totalExp)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor((mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark) * 100 / calculationToalExpAndLandmark(mercSelected).totalExp)}%`}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mercFullInfo d-flex justify-content-center align-items-center">
                                            </div>
                                        )
                                    }
                                    {
                                        mercSelected.number >= 0 ? (
                                            <div className="sellOption d-flex align-items-center">
                                                <div className="fee">
                                                    <div className="mb-1">10% Fee selling in BNB</div>
                                                    <div>7% Fee selling in Belly</div>
                                                </div>
                                                <div className="sellBtn d-flex">
                                                    <Link
                                                        to={{
                                                            pathname: '/detail',
                                                            state: {
                                                                mercSell: mercSelected,
                                                            }
                                                        }}
                                                    >
                                                        <img src={sellInBnbButton} alt=""/>
                                                    </Link>
                                                    <a href="#" onClick={() => {
                                                        setShowModalFail(true);
                                                        setTitleAlert('This feature is not available yet. Please wait for updates in the next versions.')
                                                    }}
                                                    >
                                                        <img src={sellInBellyButton} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="sellOption d-flex align-items-center">
                                                <h3 className="m-0">Select Merc for sell.</h3>
                                            </div>
                                        )
                                    }
                                    <img className="torch" src={torch} alt=""/>
                                    <img className="torch right" src={torch} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default SellMerc;
