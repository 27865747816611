import bellyIcon2 from '../../../assest/img/icon-belly.svg'
import DataContext from "../../../share/context/context";
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import badgeMerc from "../../../assest/img/badge-merc.png";
import Header from "../../layout/app/header/Header";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import {useState} from "react";
import startSVG from "../../../assest/img/star.svg";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import Web3 from "web3";
import {Link, Redirect} from "react-router-dom";
import torch from "../../../assest/img/torch.gif";
import {useLocation} from "react-router-dom"
import bob from "../../../assest/img/button-orange-blank.png";
import bcm from "../../../assest/img/button-close-metal.png";
import {ethers} from "ethers";

function SellMercDetail() {
    const location = useLocation()
    const [showModalFail, setShowModalFail] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [redirectToSellMecr, setRedirectToSellMerc] = useState(false);
    const [mercSelected, setMercSelected] = useState(location.state?.mercSell);
    const [titleAlert, setTitleAlert] = useState('');
    const [price, setPrice] = useState(0);
    const web3 = new Web3(Web3.givenProvider);
    const contractMercenary = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MERCENARY_ABI), process.env.REACT_APP_MERCENARY_ADDRESS);
    const contractMarket = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MARKET_ABI), process.env.REACT_APP_MARKET_ADDRESS);
    const addressBaned = [
        '0xB65B27efF165912E6aBFf1A01F8d92f2A3011ad4',
    ]

    const closeModalSuccess = () => {
        setShowModalSuccess(false);
        setRedirectToSellMerc(true);
        setMercSelected({})
    }

    const closeModalFail = () => {
        setShowModalFail(false)
    }

    const validateData = async (number, address, dataCt) => {
        setShowModalLoading(true)
        if (address) {
            const filterAddressBaned = addressBaned.filter(e => e.toLowerCase() === address.toLowerCase());
            if (filterAddressBaned.length > 0) {
                setTitleAlert('Your address is having an error during the withdrawal process, cannot make sales on the market.');
                setShowModalLoading(false);
                setShowModalFail(true);
            } else  {
                const filterSelected = dataCt.mercSelected.filter(e => e.number === number);
                if (filterSelected.length === 0) {
                    await approveMerc(number, address, dataCt);
                } else {
                    setTitleAlert('Remove this merc from your crew to list him/her in marketplace.');
                    setShowModalLoading(false);
                    setShowModalFail(true);
                }
            }
        } else {
            setTitleAlert('No wallet connected.');
            setShowModalLoading(false);
            setShowModalFail(true);
        }
    }

    const approveMerc = async (number, address, dataCt) => {
        contractMercenary.methods.approve(process.env.REACT_APP_MARKET_ADDRESS, number).send({
            from: address
        }).on("receipt", async (receipt) => {
            if (receipt.status) {
                await stake(number, address, dataCt)
            } else {
                setShowModalLoading(false);
                setShowModalFail(true);
                setTitleAlert('Approve Mercenary Fail.');
            }
        }).on("error", (error) => {
            setShowModalLoading(false);
        })
    }

    const stake = async (number, address, data) => {
        await contractMarket.methods.stakeNft(
            number,
            ethers.utils.parseUnits(price.toString(), 18)
        ).send({
            from: address
        }).on("receipt", async (receipt) => {
            if (receipt.status) {
                await data.updateMercInfo(address);
                setShowModalLoading(false);
                setShowModalSuccess(true);
            } else {
                setShowModalLoading(false);
                setShowModalFail(true);
                setTitleAlert('Approve Mercenary Fail.');
            }
        }).on("error", (error) => {
            setShowModalLoading(false);
        })
    }

    const changeSaleValue = (event) => {
        setPrice(event.target.value);
    }

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return {totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1]}
    }

    const numberFormat = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (redirectToSellMecr) {
        return (
            <Redirect to="/sell_merc"/>
        )
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="sellMercPage">
                    <Header page={4} saveMerc={true}/>
                    <div id="content" className="col d-flex">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <Link to="/sell_merc">
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col">
                                <div
                                    className="mercFrameMetal large d-flex flex-column align-items-center justify-content-center">
                                    <img src={badgeMerc} className="badgeFrame"/>
                                    {
                                        mercSelected && mercSelected.number !== undefined ? (
                                            <div
                                                className="mercFullInfo d-flex justify-content-center align-items-center">
                                                <div className="col-auto mercPortrait">
                                                    <img
                                                        className={
                                                            mercSelected ?
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "s-char merc-broke" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "s-char merc-cross" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "merc-loogie" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "s-char merc-monie" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "merc-sharksan" :
                                                            mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "s-char merc-slinger" : "" :""
                                                        }
                                                        src={mercSelected.avatar}
                                                        alt=""
                                                        width="250"
                                                    />
                                                </div>
                                                <div className="info">
                                                    <div className="charId">
                                                        <img src={
                                                            mercSelected.element === "fire" ? fire :
                                                                mercSelected.element === "earth" ? earth :
                                                                    mercSelected.element === "metal" ? metal :
                                                                        mercSelected.element === "water" ? water :
                                                                            mercSelected.element === "wood" ? wood :
                                                                                mercSelected.element === "dark" ? dark :
                                                                                    light
                                                        } alt="" className="element"/>
                                                        id: {mercSelected.number}
                                                    </div>
                                                    <div className="star my-4">
                                                        {
                                                            data.checkStarRarity(mercSelected.rarityNumber).map((elm, i) => (
                                                                <img src={startSVG} alt=""
                                                                     key={i}/>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="charStamina mb-4">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>stamina: {mercSelected.stamina}/150</div>
                                                            <div>{Math.floor(mercSelected.stamina * 100 / 150)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor(mercSelected.stamina * 100 / 150)}%`}}/>
                                                        </div>
                                                    </div>
                                                    <div className="charXp">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>Xp: {mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark}/{calculationToalExpAndLandmark(mercSelected).totalExp} -
                                                                LV.{mercSelected.level}</div>
                                                            <div>{Math.floor((mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark) * 100 / calculationToalExpAndLandmark(mercSelected).totalExp)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor((mercSelected.exp - calculationToalExpAndLandmark(mercSelected).expLandmark) * 100 / calculationToalExpAndLandmark(mercSelected).totalExp)}%`}}/>
                                                        </div>
                                                    </div>
                                                    <div className="fee py-1 mt-4">
                                                        <div>7% Fee selling in Belly</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="mercFullInfo d-flex justify-content-center align-items-center">

                                            </div>
                                        )
                                    }
                                    <img className="torch" src={torch} alt=""/>
                                    <img className="torch right" src={torch} alt=""/>
                                    {
                                        mercSelected && mercSelected.number !== undefined ? (
                                            <div className="inputGroup d-flex align-items-center">
                                                <div className="inputIcon d-flex align-items-center">
                                                    <input type="number" className="inputBelly"
                                                           placeholder="Enter your price"
                                                           onChange={(e) => {
                                                               changeSaleValue(e);
                                                           }}
                                                    />
                                                    <img src={bellyIcon2} className="symbol"/>
                                                </div>
                                                <button className="buttonText" onClick={async () => {
                                                    if (price && price <= 0) {
                                                        setShowModalFail(true);
                                                        setTitleAlert('Please enter price to sell.')
                                                    } else {
                                                        if (Number(price) > 999999999) {
                                                            setShowModalFail(true);
                                                            setTitleAlert(`Max price sell Mecr is ${numberFormat(999999999)} Belly.`);
                                                        } else if (Number(price) < 1) {
                                                            setShowModalFail(true);
                                                            setTitleAlert('Min price sell Mecr is 1.');
                                                        } else if (isNaN(Number(price))) {
                                                            setShowModalFail(true);
                                                            setTitleAlert('Invalid merc price.');
                                                        } else {
                                                            await validateData(mercSelected.number, data.address, data);
                                                        }
                                                    }
                                                }}>
                                                    <img src={bob} alt=""/>
                                                    <span className="text">Sell</span>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="inputGroup d-flex align-items-center">

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalSuccess} id="sellMercSuccessModal" centered onHide={closeModalSuccess}>
                        <div className="modal-content align-items-center">
                            {
                                mercSelected && mercSelected.number !== undefined ? (
                                    <div className="signSuccess">
                                        <img
                                            className={
                                                mercSelected ?
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character s-char merc-broke" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "character merc-cardinal" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character s-char merc-cross" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "character s-char merc-helsing" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "character merc-loogie" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "character s-char merc-monie" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "character merc-sharksan" :
                                                mercSelected.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "character s-char merc-slinger" : "" :""
                                            }
                                            src={mercSelected.avatar}
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    <div className="signSuccess">

                                    </div>
                                )
                            }
                            <Modal.Body
                                className="messageStarFrameMetal d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={bcm} alt="" onClick={closeModalSuccess}/>
                                <p>Your merc has been up to market and waited for buyer</p>
                            </Modal.Body>
                        </div>
                    </Modal>
                    <Modal show={showModalLoading} id="commonMessageModal" centered size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Pending…</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default SellMercDetail;
