import DataContext from "../../../share/context/context";
import Header from "../../layout/app/header/Header";
import leftArrowIcon from '../../../assest/img/icon-arrow-left.png'
import torch from "../../../assest/img/torch.gif";
import oriIcon from "../../../assest/img/icon-ori.svg";
import iconBCoin from "../../../assest/img/icon-b-coin.svg";
import iconLuckyCoin from "../../../assest/img/icon-lucky-coin.svg";
import iconBelly from "../../../assest/img/icon-belly.svg"
import buyButton from '../../../assest/img/button-buy.png'
import {Link} from "react-router-dom";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import badgeOri from '../../../assest/img/badge-ori.png'
import iconPlus from "../../../assest/img/icon-plus.png"
import iconMinus from "../../../assest/img/icon-minus.png"
import badgeStar from '../../../assest/img/badge-star.png';
import buttonReceive from '../../../assest/img/button-receive.png'
import MarketApi from '../../../share/market/market';
import Web3 from "web3";
import {ethers} from "ethers";


export default function TarvenShop() {
    const [showModalBuyOri, setShowModalBuyOri] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [textError, setTextError] = useState('');
    const [successText, setSuccessText] = useState('');
    const [numberOri, setNumberOri] = useState(10);

    const closeModalBuyOri = () => setShowModalBuyOri(false);

    const closeModalSuccess = () => setShowModalSuccess(false);

    const closeModalFail = () => setShowModalFail(false);

    const PlusNumberOri = () => {
        setNumberOri(numberOri + 10);
    }

    const MinusNumberOri = () => {
        if (numberOri > 10) {
            setNumberOri(numberOri - 10);
        }
    }

    const getAmountTransfer = (type) => {
        let amount = 0;
        setShowModalBuyOri(false);
        setLoading(true);
        //BuyOri
        if (type === 1) {
            setLoadingText('Buying ori.');
            amount = numberOri / 2;
        }
        //BuyLuckyCoin
        if (type === 2) {
            setLoadingText('Buying lucky coin.');
            amount = 300;
        }
        return amount;
    }

    const buyOriCoin = async (hash, data) => {
        const resData = await MarketApi.buyOriCoin(data.address, hash);
        if (resData.status === 200) {
            await data.getBalanceBelly(data.address);
            await data.updateOriValue(data.address);
            await data.updateAccountInfo(data.address);
            setLoading(false);
            setSuccessText(`${numberOri} Ori received`)
            setShowModalSuccess(true);
        } else {
            setLoading(false);
            setTextError(resData.message);
            setShowModalFail(true);
        }
    }

    const buyLuckyCoin = async (hash, data) => {
        const resData = await MarketApi.buyLuckyCoin(data.address, hash);
        if (resData.status === 'ok') {
            await data.getBalanceBelly(data.address);
            await data.updateOriValue(data.address);
            await data.updateAccountInfo(data.address);
            setLoading(false);
            setSuccessText(resData.message);
            setShowModalSuccess(true);
        } else {
            setLoading(false);
            setTextError(resData.message);
            setShowModalFail(true);
        }
    }

    const validateData = (data, amount) => {
        if (data.blanceBelly < amount) {
            setTextError('You don\'t have enough belly.');
            setShowModalFail(true);
            setLoading(false);
            return false;
        }
        return true;
    }

    const transferBelly = async (type, data) => {
        const amount = getAmountTransfer(type);
        if (validateData(data, amount)) {
            const web3 = new Web3(Web3.givenProvider);
            const contractBelly = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BELLY_ABI), process.env.REACT_APP_BELLY_ADDRESS);
            await contractBelly.methods.transfer(
                "0x23dFb47f22832aD8e9701F6C9743D2986c5D1CdA",
                ethers.utils.parseUnits(amount.toString(), 18)
            ).send({
                from: data.address,
            }).on("receipt", async (receipt) => {
                if (receipt.status) {
                    if (type === 1) {
                        await buyOriCoin(receipt.transactionHash, data);
                    } else {
                        await buyLuckyCoin(receipt.transactionHash, data);
                    }
                }
            }).on("error", (error) => {
                setLoading(false);
                setShowModalFail(true);
                setTextError("An error occurred, please try again later");
            })
        }
    }

    return (
        <DataContext.Consumer>
            {
                data => (
                    <>
                        <div className="wrap" id="tarvenShopPage">
                            <Header page={4} saveMerc={true}/>
                            <div id="content" className="col d-flex">
                                <div className="myCrewFrame d-flex">
                                    <div className="col-auto">
                                        <Link to="/market">
                                            <img src={leftArrowIcon} alt="" className="btnBack"/>
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <div
                                            className="mercFrameMetal large d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center mb-5">
                                                    <img src={iconLuckyCoin} width="160"/>
                                                    <div className="ms-4">
                                                        <div className="d-flex align-items-center mb-3">
                                                            <h2 className="font-riverAdventurer mb-0">Buy<br/>lucky
                                                                coin</h2>
                                                            <div className="ms-4">
                                                                <div className="mb-2">
                                                                    <img src={iconBCoin} width="30" className="me-2"/>
                                                                    40% B-Coin
                                                                </div>
                                                                <div><
                                                                    img src={oriIcon} width="30" className="me-2"/>
                                                                    60% O-Coin
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <div className="me-2">
                                                                    <img src={iconBelly} width="30"/>
                                                                </div>
                                                                <div className="price">300 belly<br/>per lucky coin
                                                                </div>
                                                            </div>
                                                            <button className="btn p-0 ms-4">
                                                                <img
                                                                    src={buyButton}
                                                                    width="80"
                                                                    onClick={async () => {
                                                                        await transferBelly(2, data);
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <img src={oriIcon} width="160"/>
                                                    <div className="ms-4">
                                                        <h2 className="font-riverAdventurer">Buy Ori</h2>
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <div className="me-2">
                                                                    <img src={iconBelly} width="30"/>
                                                                </div>
                                                                <div className="price">1 belly<br/>for 2 ori</div>
                                                            </div>
                                                            <button className="btn p-0 ms-4">
                                                                <img
                                                                    src={buyButton}
                                                                    width="80"
                                                                    onClick={() => {
                                                                        setShowModalBuyOri(true)
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img className="torch" src={torch}/>
                                            <img className="torch right" src={torch}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={showModalBuyOri} className="tarvenShopModal" id="commonMessageModal" size="lg"
                                   centered
                                   onHide={closeModalBuyOri}>
                                <Modal.Body
                                    className="commonMessageFrame d-flex align-items-center justify-content-center">
                                    <img className="closeModal" src={btc} onClick={closeModalBuyOri}/>
                                    <div className="badgeOri">
                                        <img src={badgeOri} alt=""/>
                                        <span className="text font-riverAdventurer">ori</span>
                                    </div>
                                    <div className="quantity">
                                        <p>Amount</p>
                                        <div className="d-flex align-items-center">
                                            <button className="btn p-0" onClick={MinusNumberOri}>
                                                <img src={iconMinus} alt=""/>
                                            </button>
                                            <div className="font-riverAdventurer number">{numberOri}</div>
                                            <button className="btn p-0" onClick={PlusNumberOri}>
                                                <img src={iconPlus} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="total d-flex align-items-center">
                                        <div className="number">{numberOri / 2}</div>
                                        <img src={iconBelly} className="symbol"/>
                                        <button className="btn p-0">
                                            <img
                                                src={buyButton}
                                                width="100"
                                                onClick={async () => {
                                                    await transferBelly(1, data)
                                                }}
                                            />
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={loading} id="commonMessageModal" size="lg">
                                <Modal.Body
                                    className="commonMessageFrame d-flex align-items-center justify-content-center">
                                    <div>
                                        <p>{loadingText}…</p>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={showModalSuccess} id="buyTarvenshopSuccess" centered
                                   onHide={closeModalSuccess}>
                                <Modal.Body>
                                    <div className="text-center font-riverAdventurer top">
                                        <p className="mb-1"><img src={badgeStar} alt=""/></p>
                                        <h3>{successText}</h3>
                                    </div>
                                    <div className="text-center mt-5">
                                        <a href="#">
                                            <img src={buttonReceive} width="140" onClick={closeModalSuccess}/>
                                        </a>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={showModalFail} id="commonMessageModal" centered size="lg"
                                   onHide={closeModalFail}
                                   animation={false}>
                                <Modal.Body
                                    className="commonMessageFrame d-flex align-items-center justify-content-center">
                                    <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                                    <div>
                                        <p>{textError}</p>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </>
                )
            }
        </DataContext.Consumer>
    );
}
