import bellyIcon2 from '../../../assest/img/icon-belly.svg'
import buttonBuyDisable from '../../../assest/img/button-dsb-buy.png';
import sellButton from '../../../assest/img/button-sell.png'
import myShopButton from '../../../assest/img/button-myshop.png'
import buyButton from '../../../assest/img/button-buy.png'
import star from '../../../assest/img/star.svg'
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import metalFrame1Top from '../../../assest/img/metal-frame-style-1-top.png';
import paperBoard from '../../../assest/img/paper-board.png';
import torch from '../../../assest/img/torch.gif';
import elmAll from '../../../assest/img/element-all.png';
import buttonTarvenShop from '../../../assest/img/button-tarven-shop.png';
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import {useEffect, useState} from "react";
import DataContext from "../../../share/context/context";
import Header from "../../layout/app/header/Header";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import bob from "../../../assest/img/button-orange-blank.png";
import badgeMerc from "../../../assest/img/badge-merc.png";
import bcm from "../../../assest/img/button-close-metal.png";
import Web3 from "web3";
import MarketApi from '../../../share/market/market';
import {Link} from "react-router-dom";
import {ethers} from "ethers";

function Market() {
    const [totalItem, setTotalItem] = useState([]);
    const [marketItem, setMarketItem] = useState([]);
    const [listMerFilter, setListMerFilter] = useState([]);
    const [listItemMarketFilter, setListItemMarketFilter] = useState([]);
    const [filterMer, setFilterMer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [rarityFilter, setRarityFilter] = useState('all');
    const [elementFilter, setElementFilter] = useState('all');
    const [mercShow, setMercShow] = useState({});
    const [itemShow, setItemShow] = useState({});
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [showModalFail, setShowModalFail] = useState(false);
    const [amountAllowance, setAmountAllowance] = useState(0);
    const web3 = new Web3(Web3.givenProvider);
    const contractMarket = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MARKET_ABI), process.env.REACT_APP_MARKET_ADDRESS);
    const contractBelly = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BELLY_ABI), process.env.REACT_APP_BELLY_ADDRESS);

    useEffect(async () => {
        $('.content').mCustomScrollbar()
    })

    const closeModalFail = () => {
        setShowModalFail(false);
    }

    const closeModalSuccess = () => {
        setShowModalSuccess(false);
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const getMarketData = async () => {
        const data = await MarketApi.getAllItemInMarket();
        setTotalItem(data.data.mercArr || [])
        setMarketItem(data.data.priceMercArr)
    }

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            await getMarketData();
            window.ethereum.request({method: 'eth_requestAccounts'}).then(async (account) => {
                if (account[0]) {
                    await getAmountAllowance(account[0]);
                }
            })
        }
    }, [])

    const onBuy = async (number, buyerAddress, data) => {
        await contractMarket.methods.buyNft(
            number,
            ethers.utils.parseUnits(itemShow.price.toString(), 18)
        ).send({
            from: buyerAddress
        }).on('receipt', async (receipt) => {
            if (receipt.status) {
                await data.updateMercInfo(buyerAddress);
                await getMarketData();
                await data.getBalanceBelly(buyerAddress);
                setShowModal(false);
                setShowModalLoading(false);
                setShowModalSuccess(true);
            }
        }).on('error', (error) => {
            setShowModal(false);
            setShowModalLoading(false);
            setShowModalFail(true);
            setTitleAlert("Buy Merc Error");
        })
    }

    const getAmountAllowance = async (address) => {
        await contractBelly.methods.allowance(address, process.env.REACT_APP_MARKET_ADDRESS).call((err, res) => {
            if (err) {
                console.log(err);
                return;
            }
            setAmountAllowance(Number(ethers.utils.formatUnits(res, 18)));
        })
    }

    const checkBalanceBelly = (balance, price) => {
        if (Number(balance) < Number(price)) {
            return false;
        }
        return true;
    }

    const approveAmount = async (buyerAddress, data, number) => {
        await contractBelly.methods.approve(
            process.env.REACT_APP_MARKET_ADDRESS,
            ethers.utils.parseUnits(data.blanceBelly.toString(), 18)
        ).send({
            from: buyerAddress
        }).on('receipt', async (receipt) => {
            if (receipt.status) {
                await onBuy(number, buyerAddress, data)
            }
        }).on('error', (error) => {
            setTitleAlert('Reject successfull!');
            setShowModalLoading(false);
            setShowModalFail(true);
            console.log(error);
        })
    }

    const sign = async (number, buyerAddress, data) => {
        if (checkBalanceBelly(data.blanceBelly, itemShow.price)) {
            setShowModalLoading(true);
            if (buyerAddress) {
                if (amountAllowance < itemShow.price) {
                    await approveAmount(buyerAddress, data, number);
                } else {
                    await onBuy(number, buyerAddress, data);
                }
            } else {
                setTitleAlert('No wallet connected.');
                setShowModalLoading(false);
                setShowModal(false)
                setShowModalFail(true);
            }
        } else {
            setTitleAlert('Insufficient Belly amount to buy Mecr.');
            setShowModalFail(true);
        }
    }

    const numberFormat = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const filter = (listMer, rarFl, elmFl, itemsMarket) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        const itemsMarketFilter = [];
        for (let i = 0; i < filterData.length; i++) {
            const filter = itemsMarket.filter(e => e.number === filterData[i].number);
            if (filter.length > 0) {
                itemsMarketFilter.push(filter[0]);
            }
        }
        setListItemMarketFilter(itemsMarketFilter);
        setListMerFilter(filterData);
    }

    const calculationToalExpAndLandmark = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        return {totalExp: totalExpArr[e.level - 1], expLandmark: expLandmarkArr[e.level - 1]}
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="marketPage">
                    <Header page={4} saveMerc={true}/>
                    <div id="content" className="col d-flex align-items-end">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <div className="mercProfile">
                                    <div className="metal-frame-style-1">
                                        <img src={metalFrame1Top} className="frameTop"/>
                                        <div className="frameMiddle">
                                            <div className="avataWrap">
                                                <img src="https://storage.googleapis.com/ldgood.appspot.com/char-6.png"
                                                     className="avata"/>
                                            </div>
                                        </div>
                                        <img src={metalFrame1Top} className="frameBottom"/>
                                    </div>
                                    <div className="mercName d-flex align-items-center justify-content-center">
                                        {data.address.slice(0, 4) + '...' + data.address.slice(data.address.length - 4, data.address.length)}
                                    </div>
                                    <div className="action">
                                        <Link to="/sell_merc">
                                            <img src={sellButton} alt=""/>
                                        </Link>
                                        <Link to="/my_shop">
                                            <img src={myShopButton} alt=""/>
                                        </Link>
                                    </div>
                                    <Link to="/tarven_shop">
                                        <img src={buttonTarvenShop} width="220" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <div className="crewBoard metalFrame">
                                    <div className="filterRarity d-flex align-items-center">
                                        <div className="pageName d-flex	align-items-center me-auto">
                                            <img src={paperBoard} alt=""/>
                                            <span className="text font-riverAdventurer">marketplace</span>
                                        </div>
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(totalItem, 'all', elementFilter, marketItem);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(totalItem, 'common', elementFilter, marketItem);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(totalItem, 'rare', elementFilter, marketItem);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(totalItem, 'elite', elementFilter, marketItem);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(totalItem, 'epic', elementFilter, marketItem);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(totalItem, 'legendary', elementFilter, marketItem);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(totalItem, 'mythic', elementFilter, marketItem);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(totalItem, 'godly', elementFilter, marketItem);
                                            }}
                                        >
                                            godly
                                        </div>
                                        <img src={bellyIcon2} className="symbol" alt=""/>
                                        {/*<img src={oriIcon} className="symbol" alt=""/>*/}
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(totalItem, rarityFilter, 'all', marketItem);
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(totalItem, rarityFilter, 'light', marketItem);
                                        }}/>
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(totalItem, rarityFilter, 'water', marketItem);
                                        }}/>
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(totalItem, rarityFilter, 'metal', marketItem);
                                        }}/>
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(totalItem, rarityFilter, 'dark', marketItem);
                                        }}/>
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(totalItem, rarityFilter, 'fire', marketItem);
                                        }}/>
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(totalItem, rarityFilter, 'wood', marketItem);
                                        }}/>
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(totalItem, rarityFilter, 'earth', marketItem);
                                        }}/>
                                    </div>
                                    {
                                        data.storage !== null ? (
                                            <div className="listCrew mCustomScrollbar">
                                                {
                                                    !filterMer ? (
                                                        <div className="row g-0 mt-4">
                                                            {totalItem.map((e, i) => (
                                                                <div className="col" key={i}>
                                                                    <div className="merc selected">
                                                                        <img src={e.element === "fire" ? fire :
                                                                            e.element === "earth" ? earth :
                                                                                e.element === "metal" ? metal :
                                                                                    e.element === "water" ? water :
                                                                                        e.element === "wood" ? wood :
                                                                                            e.element === "dark" ? dark :
                                                                                                light} alt=""
                                                                             className="element"/>
                                                                        {
                                                                            marketItem.filter(
                                                                                element => element.number === e.number && element.address.toLowerCase() === data.address.toLowerCase()
                                                                            ).length > 0 ? (
                                                                                <img
                                                                                    className="buttonAction"
                                                                                    src={buttonBuyDisable}
                                                                                    alt=""
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    className="buttonAction"
                                                                                    src={buyButton}
                                                                                    alt=""
                                                                                    onClick={() => {
                                                                                        setMercShow(e);
                                                                                        setItemShow(marketItem.filter(element => element.number === e.number)[0]);
                                                                                        setShowModal(true);
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                            <img src={star} alt=""
                                                                                                 key={idx}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        marketItem.filter(element => element.number === e.number).length > 0 ?
                                                                                            `${numberFormat(marketItem.filter(element => element.number === e.number)[0].price)} ` :
                                                                                            0
                                                                                    }
                                                                                    Belly
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="row g-0 mt-4">
                                                            {listMerFilter.map((e, i) => (
                                                                <div className="col" key={i}>
                                                                    <div className="merc selected">
                                                                        <img src={e.element === "fire" ? fire :
                                                                            e.element === "earth" ? earth :
                                                                                e.element === "metal" ? metal :
                                                                                    e.element === "water" ? water :
                                                                                        e.element === "wood" ? wood :
                                                                                            e.element === "dark" ? dark :
                                                                                                light} alt=""
                                                                             className="element"/>
                                                                        {
                                                                            listItemMarketFilter.filter(
                                                                                element => element.number === e.number && element.address.toLowerCase() === data.address.toLowerCase()
                                                                            ).length > 0 ? (
                                                                                <img
                                                                                    className="buttonAction"
                                                                                    src={buttonBuyDisable}
                                                                                    alt=""
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    className="buttonAction"
                                                                                    src={buyButton}
                                                                                    alt=""
                                                                                    onClick={() => {
                                                                                        setMercShow(e);
                                                                                        setItemShow(listItemMarketFilter.filter(element => element.number === e.number)[0])
                                                                                        setShowModal(true);
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "char merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "char merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "char merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "char merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "char merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                            <img src={star} alt=""
                                                                                                 key={idx}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        listItemMarketFilter.filter(element => element.number === e.number).length > 0 ?
                                                                                            `${numberFormat(listItemMarketFilter.filter(element => element.number === e.number)[0].price)} ` :
                                                                                            0
                                                                                    }
                                                                                    Belly
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div className="listCrew mCustomScrollbar">
                                                <div className="row g-0 mt-4"/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModal} id="buyMercModal" centered size='lg' onHide={closeModal}>
                        <Modal.Body
                            className="mercFrameMetal d-flex flex-column align-items-center justify-content-center">
                            <img className="closeModal" src={bcm} alt="" onClick={closeModal}/>
                            <img src={badgeMerc} className="badgeFrame"/>
                            <div className="mercFullInfo d-flex justify-content-center align-items-center">
                                <div className="col-auto mercPortrait">
                                    <img
                                        className={
                                            mercShow ?
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "s-char merc-broke" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "s-char merc-cross" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "merc-loogie" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "s-char merc-monie" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "merc-sharksan" :
                                                mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "s-char merc-slinger" : "" :""
                                        }
                                        src={mercShow.avatar}
                                        alt=""
                                        width="250"
                                    />
                                </div>
                                <div className="info">
                                    <div className="charId">
                                        <img src={
                                            mercShow.element === "fire" ? fire :
                                            mercShow.element === "earth" ? earth :
                                            mercShow.element === "metal" ? metal :
                                            mercShow.element === "water" ? water :
                                            mercShow.element === "wood" ? wood :
                                            mercShow.element === "dark" ? dark :
                                            light}
                                             alt=""
                                             className="element"
                                        />
                                        id: {mercShow.number}
                                    </div>
                                    <div className="star my-4">
                                        {
                                            data.checkStarRarity(mercShow.rarityNumber).map((elm, idx) => (
                                                <img src={star} alt=""
                                                     key={idx}/>
                                            ))
                                        }
                                    </div>
                                    <div className="charStamina mb-4">
                                        <div className="d-flex justify-content-between mb-2">
                                            <div>stamina: {mercShow.stamina}/150</div>
                                            <div>{Math.floor(mercShow.stamina * 100 / 150)}%</div>
                                        </div>
                                        <div className="processBar">
                                            <div className="percent"
                                                 style={{width: `${Math.floor(mercShow.stamina * 100 / 150)}%`}}/>
                                        </div>
                                    </div>
                                    <div className="charXp mb-0">
                                        <div className="d-flex justify-content-between mb-2">
                                            <div>Xp: {mercShow.exp - calculationToalExpAndLandmark(mercShow).expLandmark}/{calculationToalExpAndLandmark(mercShow).totalExp} -
                                                LV.{mercShow.level}</div>
                                            <div>{Math.floor((mercShow.exp - calculationToalExpAndLandmark(mercShow).expLandmark) * 100 / calculationToalExpAndLandmark(mercShow).totalExp)}%</div>
                                        </div>
                                        <div className="processBar">
                                            <div className="percent"
                                                 style={{width: `${Math.floor((mercShow.exp - calculationToalExpAndLandmark(mercShow).expLandmark) * 100 / calculationToalExpAndLandmark(mercShow).totalExp)}%`}}/>
                                        </div>
                                    </div>
                                    <div className="remainingMatches mt-3 warning text-start">
                                        Remaining
                                        matches: <span>{mercShow.max_battle_over_one_day}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                data.blanceBelly < itemShow.price ? (
                                    <p className="warning">You don't have enough Belly</p>
                                ) : ("")
                            }
                            <button
                                className={data.blanceBelly < itemShow.price ? "buttonAction btnDisabled" : "buttonAction"}
                                onClick={async () => {
                                    if (data.blanceBelly > itemShow.price) {
                                        await sign(mercShow.number, data.address, data);
                                    }
                                }}
                            >
                                <img src={bob} alt=""/>
                                <span className="buttonNumber">
                                {`${numberFormat(itemShow.price)} `}
                                    <img src={bellyIcon2} className="symbol" alt=""/>
							</span>
                            </button>
                            <img className="torch" src={torch} alt=""/>
                            <img className="torch right" src={torch} alt=""/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalLoading} id="commonMessageModal" centered size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Pending…</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalSuccess} id="buyMercSuccessModal" centered onHide={closeModalSuccess}>
                        <div className="modal-content align-items-center">
                            <div className="signSuccess">
                                <img
                                    className={
                                        mercShow ?
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character s-char merc-broke" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "character merc-cardinal" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character s-char merc-cross" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "character s-char merc-helsing" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-loogie.gif" ? "character merc-loogie" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "character s-char merc-monie" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-sharksan.gif" ? "character merc-sharksan" :
                                        mercShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "character s-char merc-slinger" : "" :""
                                    }
                                    src={mercShow.avatar}
                                    alt=""
                                />
                            </div>
                            <Modal.Body
                                className="messageStarFrameMetal d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={bcm} alt="" onClick={closeModalSuccess}/>
                                Purchase successfully Merc ID {mercShow.number}.
                            </Modal.Body>
                        </div>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Market;
