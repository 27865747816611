import DataContext from "../../../share/context/context";
import Web3 from "web3";
import {useEffect, useState} from "react";
import leftArrowIcon from "../../../assest/img/icon-arrow-left.png";
import priceWarriorPackage from "../../../assest/img/price-warrior-package.png";
import priceWarlordPackage from "../../../assest/img/price-warlord-package.png";
import priceWargodPackage from "../../../assest/img/price-wargod-package.png";
import packageWarrior from "../../../assest/img/package-warrior.png";
import packageWarlord from "../../../assest/img/package-warlord.png";
import packageWargod from "../../../assest/img/package-wargod.png";
import fire from "../../../assest/img/element-fire.png";
import water from '../../../assest/img/element-water.png';
import earth from "../../../assest/img/element-earth.png";
import metal from "../../../assest/img/element-metal.png";
import wood from "../../../assest/img/element-wood.png";
import dark from "../../../assest/img/element-dark.png";
import light from "../../../assest/img/element-light.png";
import star2 from '../../../assest/img/star.svg';
import Mercenarie from "../../../share/mercenarie/mercenarie"
import badgeStar from '../../../assest/img/badge-star.png'
import badgeOri from '../../../assest/img/badge-ori.png'
import badgeTicket from '../../../assest/img/badge-ticket.png'
import buttonReceive from '../../../assest/img/button-receive.png'
import {ethers} from "ethers";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import {Link} from "react-router-dom";
import EggApi from "../../../share/egg/egg";

function BuyPackage() {
    const web3 = new Web3(Web3.givenProvider);
    const contractBelly = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_BELLY_ABI), process.env.REACT_APP_BELLY_ADDRESS);
    const contractMercenary = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MERCENARY_ABI), process.env.REACT_APP_MERCENARY_ADDRESS);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [flag, setFlag] = useState(true);
    const [titleAlert, setTitleAlert] = useState('');
    const [titleAlert2, setTitleAlert2] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const [price, setPrice] = useState(0);
    const [storage, setStorage] = useState(null);

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            await checkFlag();
            await getEggPrice();
        }
    }, [])

    const checkFlag = async () => {
        await contractMercenary.methods._flag().call(async (err, res) => {
            if (err) {
                console.log(err);
                return;
            }
            setFlag(res);
        })
    }

    const getEggPrice = async () => {
        await contractMercenary.methods._eggPrice().call(async (err, res) => {
            if (err) {
                console.log(err);
                return;
            }
            setPrice(Number(ethers.utils.formatUnits(res, 18)));
        })
    }

    const getBalanceBnb = async (addressWl) => {
        const bl = await web3.eth.getBalance(addressWl)
        const balanceBnbConvert = ethers.utils.formatUnits(bl, 18);
        return balanceBnbConvert;
    }

    const approveAmount = async (addressWallet, contextData, quantity) => {
        const balanceBelly = await getBalanceBelly(addressWallet);
        if (balanceBelly >= (price * quantity)) {
            await executeApprove(addressWallet, balanceBelly, quantity, contextData);
        } else {
            setLoading(false);
            setShowModal2(true);
            setTitleAlert2(`Your Belly is not enough to buy the Mercenary package. Please deposit greater than or equal to ${price * quantity} Belly again!`);
        }
    }

    const getBalanceBelly = async (addressWl) => {
        let balanceBell = 0;
        await contractBelly.methods.balanceOf(addressWl).call(async (err, res) => {
            if (err) {
                setLoading(false);
                setShowModal2(true);
                setTitleAlert2('Get balance belly fail!');
                return;
            }
            balanceBell = ethers.utils.formatUnits(res, 18);
        })
        return balanceBell;
    }

    const executeBuyEgg = (quantity, addressWl, data) => {
        contractMercenary.methods.buyEgg(
            quantity
        ).send({
            from: addressWl,
        }).on("receipt", async (receipt) => {
            if (receipt.status) {
                await data.getTotalEgg(addressWl);
                await data.getBalanceBelly(addressWl);
                setLoading(false);
                awardEgg(addressWl, data, quantity);
            }
        }).on("error", (error) => {
            console.log(error)
            setLoading(false);
            setShowModal2(true);
            setTitleAlert2('Buy Mercenary Contract fail.');
        })
    }

    const awardEgg = async (address, data, quantity) => {
        if (localStorage.getItem('address') !== null) {
            setLoadingText('Do you want to sign this package right away?');
            setLoading(true);
            contractMercenary.methods.openEggsAndAwards(quantity).send({
                from: address,
            }).on('receipt', async (receipt) => {
                if (receipt.status) {
                    const newMerc = JSON.parse(await Mercenarie.getMercInArr(receipt.transactionHash, address));
                    await data.getTotalEgg(address);
                    data.updateNewMerc(newMerc.data);
                    await data.updateOriValue(data.address);
                    await data.updateListMerc(data.address);
                    setLoading(false);
                    setShowModal3(true)
                }
            }).on('error', (error) => {
                console.log(error);
                setLoading(false);
                setShowModal2(true);
                setTitleAlert2('Sign Mercenary Contract fail.');
            })
        } else {
            setTitleAlert2('No wallet connect.');
            setShowModal2(true);
        }
    }

    const executeApprove = async (addressWl, balanceBelly, quantity, data) => {
        contractBelly.methods.approve(
            process.env.REACT_APP_MERCENARY_ADDRESS,
            ethers.utils.parseUnits(balanceBelly, 18),
        ).send({
            from: addressWl,
        }).on("receipt", async (receipt) => {
            if (receipt.status) {
                await executeBuyEgg(quantity, addressWl, data);
            }
        }).on("error", (err) => {
            setLoading(false)
            setShowModal2(true);
            setTitleAlert2('Approve Not Successfully!');
        })
    }

    const getAmountAllowance = async (addressWallet) => {
        let amountAllowance = 0;
        await contractBelly.methods.allowance(addressWallet, process.env.REACT_APP_MERCENARY_ADDRESS).call(function (err, res) {
            if (err) {
                setLoading(false);
                setShowModal2(true);
                setTitleAlert2('Connect to Wallet fail! Please check chain of wallet!');
                return;
            }
            amountAllowance = ethers.utils.formatUnits(res, 18);
        })
        return amountAllowance;
    }

    const checkAmountBnbAndApprove = async (addressWl, data, quantity) => {
        await approveAmount(addressWl, data, quantity);
    }

    const buyEggs = async (addressWl, data, quantity) => {
        if (flag) {
            if (data.storage !== null && localStorage.getItem('address') !== null && addressWl) {
                setLoadingText('Please wait! Your Mercenary Contract will be delivered shortly!');
                setLoading(true);
                const amountAllowance = await getAmountAllowance(addressWl);
                if (amountAllowance < (price * quantity)) {
                    await checkAmountBnbAndApprove(addressWl, data, quantity);
                } else {
                    const balanceBelly = await getBalanceBelly(addressWl);
                    if (balanceBelly < (price * quantity)) {
                        setLoading(false);
                        setShowModal2(true);
                        setTitleAlert2(`Your Belly is not enough to buy the Mercenary package. Please deposit greater than or equal to ${price * quantity} Belly again!`);
                        return;
                    } else {
                        const balanceBnbConvert = await getBalanceBnb(addressWl);
                        await executeBuyEgg(quantity, addressWl, data);
                    }
                }
            } else {
                setShowModal2(true);
                setTitleAlert2('No wallet connect!');
            }
        } else {
            setShowModal2(true);
            setTitleAlert2('Package purchase expires.');
        }
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const closeModal2 = () => {
        setShowModal2(false)
    }

    const closeModal3 = () => {
        setShowModal3(false)
    }

    return (
        <DataContext.Consumer>
            {
                data => (
                    <div className="wrap" id="buyPackagePage">
                        <div className="d-flex">
                            <div className="col-auto">
                                <Link to="/merc_center">
                                    <img src={leftArrowIcon} alt="" className="btnBack"/>
                                </Link>
                            </div>
                            <div className="col">
                                <div className="row text-center justify-content-center">
                                    <div className="col-6">
                                        <div className="item">
                                            <img src={packageWarrior} className="mainImg"/>
                                            <a href="#" className="price">
                                                <img src={priceWarriorPackage} alt="" onClick={async () => {
                                                    await buyEggs(data.address, data, 1);
                                                }}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="item">
                                            <img src={packageWarlord} className="mainImg"/>
                                            <a href="#" className="price">
                                                <img src={priceWarlordPackage} alt="" onClick={async () => {
                                                    await buyEggs(data.address, data, 4);
                                                }}/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="item mt-4">
                                            <img src={packageWargod} className="mainImg"/>
                                            <a href="#" className="price">
                                                <img src={priceWargodPackage} alt="" onClick={async () => {
                                                    await buyEggs(data.address, data, 8);
                                                }}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* LOADING MODAL */}
                        <Modal show={loading} id="commonMessageModal" size="lg">
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <div>
                                    <p>{loadingText}…</p>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* FAIL MODAL */}
                        <Modal show={showModal2} id="commonMessageModal" centered size="lg" onHide={closeModal2}
                               animation={false}>
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                                <div>
                                    <p>{titleAlert2}</p>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* SUCCESS MODAL */}
                        <Modal show={showModal} id="buyModal" onHide={closeModal}>
                            <Modal.Body className="messageStarFrame">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                                {titleAlert}
                            </Modal.Body>
                        </Modal>

                        {/* BUY PACKAGE MODAL */}
                        <Modal show={showModal3} id="buyPackageModal" centered fullscreen onHide={closeModal3}>
                            <Modal.Body className="modal-content">
                                <div className="modal-body listReward">
                                    <div className="inner">
                                        <div className="text-center font-riverAdventurer top">
                                            <p className="mb-1"><img src={badgeStar} alt="" width="250"/></p>
                                            <h5>buy success</h5>
                                        </div>
                                        <div className="row g-0 justify-content-center align-items-center">
                                            {
                                                data.newMerc ?
                                                    (
                                                        <>
                                                            {data.newMerc.map((item, index) => (
                                                                <div className="col-auto" key={index}>
                                                                    <div className="signSuccess">
                                                                        <img
                                                                            className={
                                                                                item.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character merc-cross" :
                                                                                    item.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character merc-broke" : "character"
                                                                            }
                                                                            src={item.avatar}
                                                                            alt=""
                                                                        />
                                                                        <img alt="" className="element"
                                                                             src={
                                                                                 item.element === "fire" ? fire :
                                                                                     item.element === "earth" ? earth :
                                                                                         item.element === "metal" ? metal :
                                                                                             item.element === "water" ? water :
                                                                                                 item.element === "wood" ? wood :
                                                                                                     item.element === "dark" ? dark :
                                                                                                         light
                                                                             }
                                                                        />
                                                                        <div className="star">
                                                                            {
                                                                                data.checkStarRarity(item.rarityNumber).map((e, i) => (
                                                                                    <img src={star2} alt=""
                                                                                         key={i}/>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )
                                            }
                                            <div className="col-auto align-self-start">
                                                <div className="bonus">
                                                    <div className="ori">
                                                        <img src={badgeOri} alt=""/>
                                                        <div className="totalBonus">
                                                            <div className="number font-riverAdventurer">
                                                                {
                                                                    data.newMerc ? data.newMerc.length === 4 ? "+ 500" : data.newMerc.length === 8 ? "+ 1500" : data.newMerc.length === 1 ? '+ 0' : "" : ""
                                                                }
                                                            </div>
                                                            <div className="text">ori</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <a href="#"><img src={buttonReceive} alt="" data-bs-dismiss="modal"
                                                             width="140" onClick={closeModal3}/></a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            }
        </DataContext.Consumer>
    );
}

export default BuyPackage;