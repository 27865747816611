import React from 'react';

class Account {
    createAccount = async (address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address.toLowerCase()
            })
        }).then(res => res)
    }

    withdrawReward = async (address, dataThatWasSigned, signature) => {
        return await fetch(
            `${process.env.REACT_APP_API_URL}/pirate/withdraw?address=${address}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`
        ).then(res => res.text());
    }

    getAccountByAddress = async (address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate?address=${address.toLowerCase()}`).then(res => res.text())
    }

    getMercByAddress = async (address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/mercenarie?address=${address.toLowerCase()}`).then(res => res.text())
    }

    updateTeamAttack = async (address, selectedList, dataThatWasSigned, signature) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate/updateTeam`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address.toLowerCase(),
                mercSelected: selectedList,
                updatedAt: 0,
                dataThatWasSigned: dataThatWasSigned.toString(),
                signature,
            })
        }).then(res => res.text())
    }

    getAllTokenNFT = async (address) => {
        return await fetch(
            `https://api-testnet.bscscan.com/api?module=account&action=tokennfttx&contractaddress=${process.env.REACT_APP_MERCENARY_ADDRESS}&address=${address}&page=1&offset=700&sort=asc&apikey=NKRVGGX2BCMYSDS2XB4KQE2XMUPWVDUEAR`
        ).then(res => res.text())
    }

    getDetailReward = async (address) => {
        return await fetch(
            `${process.env.REACT_APP_API_URL}/pirate/getDetailReward?address=${address.toLowerCase()}`
        ).then(res => res.text())
    }

    getTransactionAttack = async (address) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/transactionAttack?address=${address.toLowerCase()}`).then(res => res.text())
    }

    openOc = async (address, dataThatWasSigned, signature) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate/useOCoin?address=${address.toLowerCase()}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`)
            .then(res => res.text())
    }

    openBc = async (address, dataThatWasSigned, signature) => {
        return await fetch(`${process.env.REACT_APP_API_URL}/pirate/useBCoin?address=${address.toLowerCase()}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`)
            .then(res => res.text())
    }
}

export default new Account();
