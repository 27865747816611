import Logo from '../../../assest/img/logo.png';
import MenuMarket from '../../../assest/img/menu-market.png';
import MenuMyCrew from '../../../assest/img/menu-my-crew.png';
import MenuMercCenter from '../../../assest/img/menu-merc-center.png';
import MenuBattleField from '../../../assest/img/menu-battle-field.png';
import MenuFow from '../../../assest/img/menu-fow.png';
import {Link} from "react-router-dom";

function HomePage() {

    return (
        <div className="wrap" id="introPage">
            <div className="row">
                <div className="col d-flex flex-column align-items-end">
                    <Link to="/market" className="menuMarket">
                        <img src={MenuMarket} alt=""/>
                    </Link>
                    <Link to="/my_crew" className="menuMyCrew">
                        <img src={MenuMyCrew} alt=""/>
                    </Link>
                </div>
                <div className="col-auto">
                    <div className="text-center">
                        <img className="logo" src={Logo}/>
                    </div>
                    <Link to="/merc_center" className="menuMercCenter">
                        <img src={MenuMercCenter} alt=""/>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/battle_field" className="menuBattleField">
                        <img src={MenuBattleField} alt=""/>
                    </Link>
                    <Link to="/fow" className="menuFow">
                        <img src={MenuFow} alt=""/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
