import { Modal } from "react-bootstrap";
import water from '../../../assest/img/element-water.png';
import DataContext from "../../../share/context/context";
import Web3 from "web3";
import { useEffect, useState } from "react";
import fire from "../../../assest/img/element-fire.png";
import earth from "../../../assest/img/element-earth.png";
import metal from "../../../assest/img/element-metal.png";
import wood from "../../../assest/img/element-wood.png";
import dark from "../../../assest/img/element-dark.png";
import light from "../../../assest/img/element-light.png";
import star2 from '../../../assest/img/star.svg';
import mystery from '../../../assest/img/mystery.png'
import btc from '../../../assest/img/button-close.png'
import badgeStar from '../../../assest/img/badge-star.png'
import badgeOri from '../../../assest/img/badge-ori.png'
import badgeTicket from '../../../assest/img/badge-ticket.png'
import buttonReceive from '../../../assest/img/button-receive.png'
import buttonContract4 from '../../../assest/img/button-pack-4.png'
import buttonContract8 from '../../../assest/img/button-pack-8.png'
import buttonContract1 from '../../../assest/img/button-pack-1.png'
import buttonPack from '../../../assest/img/button-pack.png'
import Header from "../../layout/app/header/Header";
import Mercenarie from "../../../share/mercenarie/mercenarie"
import { ethers } from "ethers";
import { Link } from "react-router-dom";

function MercCenter() {
    const web3 = new Web3(Web3.givenProvider);
    const contractMercenary = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_MERCENARY_ABI), process.env.REACT_APP_MERCENARY_ADDRESS);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [titleAlert2, setTitleAlert2] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const [price, setPrice] = useState(0);
    const [storage, setStorage] = useState(null);

    useEffect(async () => {
        if (window.ethereum && window.web3) {
            await getEggPrice()
            setStorage(localStorage.getItem('address'));
            if (localStorage.getItem('address') !== null) {
                window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (account) => {
                })
            }
        }
    }, [storage])


    const getEggPrice = async () => {
        await contractMercenary.methods._eggPrice().call(async (err, res) => {
            if (err) {
                console.log(err);
                return;
            }
            setPrice(Number(ethers.utils.formatUnits(res, 18)));
        })
    }

    const sign = async (address, data, quantity) => {
        if (data.storage !== null) {
            if (data.listEgg >= quantity) {
                await awardEgg(address, data, quantity);
            } else {
                setTitleAlert2('Insufficient Mercenary to sign.');
                setShowModal2(true);
            }
        } else {
            setShowModal2(true);
            setTitleAlert2('No wallet connect!');
        }
    }

    const awardEgg = async (address, data, quantity) => {
        if (localStorage.getItem('address') !== null) {
            setLoadingText('Signing Contract...');
            setLoading(true);
            contractMercenary.methods.openEggsAndAwards(quantity).send({
                from: address,
            }).on('receipt', async (receipt) => {
                if (receipt.status) {
                    const newMerc = JSON.parse(await Mercenarie.getMercInArr(receipt.transactionHash, address));
                    await data.getTotalEgg(address);
                    data.updateNewMerc(newMerc.data);
                    await data.updateListMerc(data.address);
                    await data.updateOriValue(data.address);
                    setLoading(false);
                    // setTitleAlert('Congratulation! A Mercenary has been hired!');
                    setShowModal3(true);
                }
            }).on('error', (error) => {
                console.log(error);
                setLoading(false);
                setShowModal2(true);
                setTitleAlert2('Sign Mercenary Contract fail.');
            })
        } else {
            setTitleAlert2('No wallet connect.');
            setShowModal2(true);
        }
    }

    const closeModal = () => setShowModal(false)

    const closeModal2 = () => setShowModal2(false)

    const closeModal3 = () => setShowModal3(false)

    return (
        <DataContext.Consumer>
            {
                data => (
                    <div className="wrap" id="mercCenterPage">
                        <Header page={1} saveMerc={data.saveMercSlt} />
                        <div id="content" className="col d-flex align-items-center justify-content-center flex-column">
                            {/* {*/}
                            {/*    data.newMerc === null ? (*/}
                            {/*        <img src={mystery} alt="" className="mystery" />*/}
                            {/*    ) : (*/}
                            {/*        <div className="d-flex">*/}
                            {/*            {*/}
                            {/*                data.newMerc.map((item, index) => (*/}
                            {/*                    <div className="signSuccess d-none_" key={index}>*/}
                            {/*                        <img className="character" src={item.avatar} alt="" />*/}
                            {/*                        <div className="charId">*/}
                            {/*                            <img*/}
                            {/*                                className="element"*/}
                            {/*                                src={*/}
                            {/*                                    item.element === "fire" ? fire :*/}
                            {/*                                        item.element === "earth" ? earth :*/}
                            {/*                                            item.element === "metal" ? metal :*/}
                            {/*                                                item.element === "water" ? water :*/}
                            {/*                                                    item.element === "wood" ? wood :*/}
                            {/*                                                        item.element === "dark" ? dark :*/}
                            {/*                                                            light*/}
                            {/*                                }*/}
                            {/*                            />*/}
                            {/*                            id: {item.number}*/}
                            {/*                            <div className="star">*/}
                            {/*                                {*/}
                            {/*                                    data.checkStarRarity(item.rarityNumber).map((e, i) => (*/}
                            {/*                                        <img src={star2} alt=""*/}
                            {/*                                            key={i} />*/}
                            {/*                                    ))*/}
                            {/*                                }*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                ))*/}
                            {/*            }*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}
                            <img src={mystery} alt="" className="mystery" />
                        </div>
                        <div className="action d-flex align-items-end">
                            <div className="col buyContract text-end">
                                <div className="row align-items-center justify-content-end g-0">
                                    <div className="col-auto me-3">
                                        <Link to="/buy_package"><img src={buttonPack} alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            {
                                data.storage === null ? (
                                    <div className="totalContract font-riverAdventurer col-auto">
                                        0 CONTRACTS <span className="text">available</span>
                                    </div>
                                ) : (
                                    <div className="totalContract font-riverAdventurer col-auto">
                                        {data.listEgg} CONTRACTS <span className="text">available</span>
                                    </div>
                                )
                            }
                            <div className="col buyContract">
                                <a href="#" onClick={async () => {
                                    await sign(data.address, data, 1);
                                }}>
                                    <img src={buttonContract1} alt="1" />
                                </a>
                                <a href="#" onClick={async () => {
                                    await sign(data.address, data, 4);
                                }}>
                                    <img src={buttonContract4} alt="4" />
                                </a>
                                <a href="#" onClick={async () => {
                                    await sign(data.address, data, 8);
                                }}>
                                    <img src={buttonContract8} alt="8" />
                                </a>
                                &nbsp;
                            </div>
                        </div>

                        {/* SUCCESS MODAL */}
                        <Modal show={showModal} id="buyModal" onHide={closeModal}>
                            <Modal.Body className="messageStarFrame">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal} />
                                {titleAlert}
                            </Modal.Body>
                        </Modal>

                        {/* LOADING MODAL */}
                        <Modal show={loading} id="commonMessageModal" size="lg">
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <div>
                                    <p>{loadingText}…</p>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* FAIL MODAL */}
                        <Modal show={showModal2} id="commonMessageModal" centered size="lg" onHide={closeModal2}
                            animation={false}>
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal2} />
                                <div>
                                    <p>{titleAlert2}</p>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* BUY PACKAGE MODAL */}
                        <Modal show={showModal3} id="buyPackageModal" centered fullscreen onHide={closeModal3}>
                            <Modal.Body className="modal-content">
                                <div className="modal-body listReward">
                                    <div className="inner">
                                        <div className="text-center font-riverAdventurer top">
                                            <p className="mb-1"><img src={badgeStar} alt="" width="250"/></p>
                                            <h5>buy success</h5>
                                        </div>
                                        <div className="row g-0 justify-content-center align-items-center">
                                            {
                                                data.newMerc ?
                                                    (
                                                        <>
                                                            {data.newMerc.map((item, index) => (
                                                                <div className="col-auto" key={index}>
                                                                    <div className="signSuccess">
                                                                        <img
                                                                            className={
                                                                                item.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "character merc-cross" :
                                                                                    item.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "character merc-broke" : "character"
                                                                            }
                                                                            src={item.avatar}
                                                                            alt=""
                                                                        />
                                                                        <img alt="" className="element"
                                                                             src={
                                                                                 item.element === "fire" ? fire :
                                                                                     item.element === "earth" ? earth :
                                                                                         item.element === "metal" ? metal :
                                                                                             item.element === "water" ? water :
                                                                                                 item.element === "wood" ? wood :
                                                                                                     item.element === "dark" ? dark :
                                                                                                         light
                                                                             }
                                                                        />
                                                                        <div className="star">
                                                                            {
                                                                                data.checkStarRarity(item.rarityNumber).map((e, i) => (
                                                                                    <img src={star2} alt=""
                                                                                         key={i}/>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )
                                            }
                                            <div className="col-auto align-self-start">
                                                <div className="bonus">
                                                    <div className="ori">
                                                        <img src={badgeOri} alt=""/>
                                                        <div className="totalBonus">
                                                            <div className="number font-riverAdventurer">
                                                                {
                                                                    data.newMerc ? data.newMerc.length === 4 ? "+ 500" : data.newMerc.length === 8 ? "+ 1500" : data.newMerc.length === 1 ? '+ 0' : "" : ""
                                                                }
                                                            </div>
                                                            <div className="text">ori</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <a href="#"><img src={buttonReceive} alt="" data-bs-dismiss="modal"
                                                             width="140" onClick={closeModal3}/></a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </div>
                )
            }
        </DataContext.Consumer>
    );
}

export default MercCenter;
