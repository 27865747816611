import {Modal} from "react-bootstrap";
import Account from '../../../share/account/account'
import DataContext from "../../../share/context/context";
import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import elmAll from '../../../assest/img/element-all.png';
import buttonLevelUp from '../../../assest/img/button-level-up.png';
import buttonUpgrade from '../../../assest/img/button-upgrade.png';
import buttonRelease from '../../../assest/img/button-release.png';
import buttonRelease1 from '../../../assest/img/button-release-1.png';
import buttonTeamSettings from '../../../assest/img/button-team-settings.png';
import {useEffect, useState} from "react";
import Header from "../../layout/app/header/Header";
import startSVG from "../../../assest/img/star.svg";
import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import btc from "../../../assest/img/button-close.png";
import {Link} from "react-router-dom";
import Web3 from "web3";
import buttonUpgradeOne from "../../../assest/img/button-upgrade-1.png";
import iconBelly from "../../../assest/img/icon-belly.svg";
import oriIcon from "../../../assest/img/icon-ori.svg";
import MerApi from "../../../share/mercenarie/mercenarie";

function NewMyCrew() {
    const [elementFilter, setElementFilter] = useState('all');
    const [rarityFilter, setRarityFilter] = useState('all');
    const [type, setType] = useState('');
    const [textSuccess, setTextSuccess] = useState('');
    const [filterMer, setFilterMer] = useState(false);
    const [listMerFilter, setListMerFilter] = useState([]);
    const [totalExp, setTotalExp] = useState(0);
    const [expLandmark, setExpLandmark] = useState(0);
    const [merShow, setMerShow] = useState(null);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalFail, setShowModalFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [textError, setTextError] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [amountReward, setAmountReward] = useState(0);
    const [bellyReceived, setBellyReceived] = useState(0);
    const [oriReceived, setOriReceived] = useState(0);

    const changeMercShow = (e) => {
        const totalExpArr = [
            30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
            450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
            3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
        ]
        const expLandmarkArr = [
            0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
            3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
            14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
            39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
        ]
        if (e.rarityNumber > 1) {
            setBellyReceived(1000 + (e.rarityNumber - 1) * 200);
            setOriReceived(2000 + (e.rarityNumber - 1) * 400);
        } else {
            setBellyReceived(1000)
            setOriReceived(2000);
        }
        setTotalExp(totalExpArr[e.level - 1])
        setExpLandmark(expLandmarkArr[e.level - 1])
        setMerShow(e);
    }

    useEffect(() => {
        $('.content').mCustomScrollbar()
    })

    const filter = (listMer, rarFl, elmFl) => {
        setFilterMer(true);
        let filterData = []
        if (rarFl === 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl);
        } else if (rarFl !== 'all' && elmFl === 'all') {
            filterData = listMer.filter(e => e.rarity === rarFl);
        } else if (rarFl !== 'all' && elmFl !== 'all') {
            filterData = listMer.filter(e => e.element === elmFl && e.rarity === rarFl);
        } else if (rarFl === 'all' && elmFl === 'all') {
            setFilterMer(false);
        }
        setListMerFilter(filterData);
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const closeModalFail = () => {
        setShowModalFail(false);
    }

    const closeModalSuccess = () => {
        setShowModalSuccess(false);
    }

    const validateMec = (data) => {
        const checkMerSelected = data.mercSelected.filter(e => e.number === merShow.number);
        if (checkMerSelected.length > 0) {
            setShowModalConfirm(false);
            setShowModalFail(true);
            setTextError("You have already selected this mercenary");
            return false;
        }
        if (type.length === 0) {
            setShowModalConfirm(false);
            setShowModalFail(true);
            setTextError("Please select the reward you want to receive.");
            return false;
        }
        return true;
    }

    const sign = async (data) => {
        if (validateMec(data)) {
            setShowModalConfirm(false);
            setLoading(true);
            const timeStamp = new Date().getTime();
            const web3 = new Web3(Web3.givenProvider);
            await web3.eth.personal.sign(timeStamp.toString(), data.address, async (error, signature) => {
                if (error) {
                    setLoading(false)
                    return;
                }
                await detached(data, signature, timeStamp);
            })
        }
    }

    const detached = async (data, signature, dataThatWasSigned) => {
        const response = JSON.parse(await MerApi.detached(data.address, merShow.number, type, signature, dataThatWasSigned));
        console.log(response);
        if (response.status === 200) {
            if (type === "detached") {
                setAmountReward(response.data.oriValue);
            }
            if (type === "terminate") {
                setAmountReward(response.data.withdrawalOrder.amount);
            }
            await data.getBalanceBelly(data.address);
            await data.updateOriValue(data.address);
            await data.updateMercInfo(data.address);
            setElementFilter('all');
            setRarityFilter('all');
            setFilterMer(false);
            setMerShow(null);
            setLoading(false);
            setShowModalSuccess(true);
        } else {
            setLoading(false);
            setShowModalFail(true);
            setTextError(response.message);
        }
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap" id="myCrewPage">
                    <Header page={2} saveMerc={data.saveMercSlt}/>
                    <div id="content" className="col d-flex align-items-end">
                        <div className="myCrewFrame d-flex">
                            <div className="col-auto">
                                <div className="crewBoard crewBoardHeader">
                                    <div className="row g-0 justify-content-between align-items-center">
                                        <div className="totalCrew col-auto">
                                            Your crew: {data.mercList.length} merc
                                        </div>
                                        <div className="listCrew col-auto">
                                            {
                                                data.storage !== null ? (
                                                    <div className="row g-0 justify-content-end">
                                                        {
                                                            data.mercSelected.map((e, i) => (
                                                                <div className="col-3" key={i}>
                                                                    <div className="merc">
                                                                        <img
                                                                            src={
                                                                                e.element === "fire" ? fire :
                                                                                e.element === "earth" ? earth :
                                                                                e.element === "metal" ? metal :
                                                                                e.element === "water" ? water :
                                                                                e.element === "wood" ? wood :
                                                                                e.element === "dark" ? dark :
                                                                                light
                                                                            }
                                                                            alt=""
                                                                            className="element"
                                                                        />
                                                                        <div className="mercOverview">
                                                                            <div className="thumbMercWrap">
                                                                                <img
                                                                                    className={
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cardinal.gif" ? "merc-cardinal" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" : "char"
                                                                                    }
                                                                                    src={e.avatar}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info">
                                                                                <div className="star">
                                                                                    {
                                                                                        data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                            <img src={startSVG} alt=""
                                                                                                 key={idx}/>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            data.mercSelected.length < 1 ? (
                                                                <div className="col-3">
                                                                    <div className="merc">
                                                                    </div>
                                                                </div>
                                                            ) : ""
                                                        }
                                                        {
                                                            data.mercSelected.length < 2 ? (
                                                                <div className="col-3">
                                                                    <div className="merc">
                                                                    </div>
                                                                </div>
                                                            ) : ""
                                                        }
                                                        {
                                                            data.mercSelected.length < 3 ? (
                                                                <div className="col-3">
                                                                    <div className="merc">
                                                                    </div>
                                                                </div>
                                                            ) : ""
                                                        }
                                                        {
                                                            data.mercSelected.length < 4 ? (
                                                                <div className="col-3">
                                                                    <div className="merc">
                                                                    </div>
                                                                </div>
                                                            ) : ""
                                                        }
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row g-0 justify-content-end">
                                                            <div className="col-3">
                                                                <div className="merc">
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="merc">
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="merc">
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="merc">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <button className="btn btnTeamSetting">\
                                                <Link to="/team_setting">
                                                    <img src={buttonTeamSettings} width="110"/>
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="crewBoard">
                                    <div className="filterRarity d-flex justify-content-between">
                                        <div
                                            className={rarityFilter === 'all' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('all');
                                                filter(data.mercList, 'all', elementFilter);
                                            }}
                                        >
                                            All
                                        </div>
                                        <div
                                            className={rarityFilter === 'common' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('common');
                                                filter(data.mercList, 'common', elementFilter);
                                            }}
                                        >
                                            common
                                        </div>
                                        <div
                                            className={rarityFilter === 'rare' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('rare');
                                                filter(data.mercList, 'rare', elementFilter);
                                            }}
                                        >
                                            rare
                                        </div>
                                        <div
                                            className={rarityFilter === 'elite' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('elite');
                                                filter(data.mercList, 'elite', elementFilter);
                                            }}
                                        >
                                            elite
                                        </div>
                                        <div
                                            className={rarityFilter === 'epic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('epic');
                                                filter(data.mercList, 'epic', elementFilter);
                                            }}
                                        >
                                            epic
                                        </div>
                                        <div
                                            className={rarityFilter === 'legendary' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('legendary');
                                                filter(data.mercList, 'legendary', elementFilter);
                                            }}
                                        >
                                            legendary
                                        </div>
                                        <div
                                            className={rarityFilter === 'mythic' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('mythic');
                                                filter(data.mercList, 'mythic', elementFilter);
                                            }}
                                        >
                                            mythic
                                        </div>
                                        <div
                                            className={rarityFilter === 'godly' ? "item active" : "item"}
                                            onClick={() => {
                                                setRarityFilter('godly');
                                                filter(data.mercList, 'godly', elementFilter);
                                            }}
                                        >
                                            godly
                                        </div>
                                    </div>
                                    <div className="filterElement">
                                        <img
                                            src={elmAll}
                                            alt=""
                                            onClick={() => {
                                                setElementFilter('all');
                                                filter(data.mercList, rarityFilter, 'all');
                                            }}
                                        />
                                        <img src={light} alt="" onClick={() => {
                                            setElementFilter('light');
                                            filter(data.mercList, rarityFilter, 'light');
                                        }}/>
                                        <img src={water} alt="" onClick={() => {
                                            setElementFilter('water');
                                            filter(data.mercList, rarityFilter, 'water');
                                        }}/>
                                        <img src={metal} alt="" onClick={() => {
                                            setElementFilter('metal');
                                            filter(data.mercList, rarityFilter, 'metal');
                                        }}/>
                                        <img src={dark} alt="" onClick={() => {
                                            setElementFilter('dark');
                                            filter(data.mercList, rarityFilter, 'dark');
                                        }}/>
                                        <img src={fire} alt="" onClick={() => {
                                            setElementFilter('fire');
                                            filter(data.mercList, rarityFilter, 'fire');
                                        }}/>
                                        <img src={wood} alt="" onClick={() => {
                                            setElementFilter('wood');
                                            filter(data.mercList, rarityFilter, 'wood');
                                        }}/>
                                        <img src={earth} alt="" onClick={() => {
                                            setElementFilter('earth');
                                            filter(data.mercList, rarityFilter, 'earth');
                                        }}/>
                                    </div>
                                    <div className="listCrew mCustomScrollbar">
                                        {
                                            !filterMer ? (
                                                <div className="row g-0">
                                                    {
                                                        data.mercList.map((e, i) => (
                                                            <div
                                                                className="col-3"
                                                                key={i}
                                                                onClick={() => {
                                                                    changeMercShow(e);
                                                                }}
                                                            >
                                                                <div
                                                                    className={data.mercSelected.filter(em => em.number === e.number).length === 0 ? "merc" : "merc selected"}>
                                                                    <img
                                                                        src={
                                                                            e.element === "fire" ? fire :
                                                                                e.element === "earth" ? earth :
                                                                                    e.element === "metal" ? metal :
                                                                                        e.element === "water" ? water :
                                                                                            e.element === "wood" ? wood :
                                                                                                e.element === "dark" ? dark :
                                                                                                    light
                                                                        }
                                                                        alt=""
                                                                        className="element"
                                                                    />
                                                                    <div className="mercOverview">
                                                                        <div className="thumbMercWrap">
                                                                            <img
                                                                                className={
                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" : "char"
                                                                                }
                                                                                src={e.avatar}
                                                                            />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="star">
                                                                                {
                                                                                    data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                        <img src={startSVG} alt=""
                                                                                             key={idx}/>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className="row g-0">
                                                    {
                                                        listMerFilter.map((e, i) => (
                                                            <div
                                                                className="col-3"
                                                                key={i}
                                                                onClick={() => {
                                                                    changeMercShow(e);
                                                                }}
                                                            >
                                                                <div
                                                                    className={data.mercSelected.filter(em => em.number === e.number).length === 0 ? "merc" : "merc selected"}>
                                                                    <img
                                                                        src={
                                                                            e.element === "fire" ? fire :
                                                                                e.element === "earth" ? earth :
                                                                                    e.element === "metal" ? metal :
                                                                                        e.element === "water" ? water :
                                                                                            e.element === "wood" ? wood :
                                                                                                e.element === "dark" ? dark :
                                                                                                    light
                                                                        }
                                                                        alt=""
                                                                        className="element"
                                                                    />
                                                                    <div className="mercOverview">
                                                                        <div className="thumbMercWrap">
                                                                            <img
                                                                                className={
                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                                        e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                                            e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                                                e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" :
                                                                                                    e.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-slinger.gif" ? "merc-slinger" : "char"
                                                                                }
                                                                                src={e.avatar}
                                                                            />
                                                                        </div>
                                                                        <div className="info">
                                                                            <div className="star">
                                                                                {
                                                                                    data.checkStarRarity(e.rarityNumber).map((elm, idx) => (
                                                                                        <img src={startSVG} alt=""
                                                                                             key={idx}/>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto align-self-end rightSide">
                                <div className="mercFullInfo d-flex justify-content-center align-items-center">
                                    {
                                        merShow !== null ? (
                                            <>
                                                <div className="mercPortrait">
                                                    <img
                                                        className={
                                                            merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-broke.gif" ? "merc-broke" :
                                                                merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-cross.gif" ? "merc-cross" :
                                                                    merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-helsing.gif" ? "merc-helsing" :
                                                                        merShow.avatar === "https://storage.googleapis.com/ldgood.appspot.com/merc-monie.gif" ? "merc-monie" : "char"
                                                        }
                                                        src={merShow.avatar}
                                                    />
                                                </div>
                                                <div className="info">
                                                    <div className="charId">
                                                        <img
                                                            src={
                                                                merShow.element === "fire" ? fire :
                                                                    merShow.element === "earth" ? earth :
                                                                        merShow.element === "metal" ? metal :
                                                                            merShow.element === "water" ? water :
                                                                                merShow.element === "wood" ? wood :
                                                                                    merShow.element === "dark" ? dark :
                                                                                        light
                                                            }
                                                            alt=""
                                                            className="element"
                                                        />
                                                        id: {merShow.number}
                                                    </div>
                                                    <div className="star">
                                                        {
                                                            data.checkStarRarity(merShow.rarityNumber).map((elm, idx) => (
                                                                <img src={startSVG} alt=""
                                                                     key={idx}/>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="charStamina">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>stamina: {merShow.stamina}/150</div>
                                                            <div>{Math.floor(merShow.stamina * 100 / 150)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor(merShow.stamina * 100 / 150)}%`}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="charXp">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div>Xp: {merShow.exp - expLandmark}/{totalExp} -
                                                                LV.{merShow.level}</div>
                                                            <div>{Math.floor((merShow.exp - expLandmark) * 100 / totalExp)}%</div>
                                                        </div>
                                                        <div className="processBar">
                                                            <div className="percent"
                                                                 style={{width: `${Math.floor((merShow.exp - expLandmark) * 100 / totalExp)}%`}}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                                <div className="row g-0 align-items-center">
                                    <div className="col-auto upgrade">
                                        <button className="btn">
                                            <Link
                                                to={{
                                                    pathname: '/level_up',
                                                    state: {
                                                        merc: merShow,
                                                    }
                                                }}
                                            >
                                                <img src={buttonLevelUp} width="100"/>
                                            </Link>
                                        </button>
                                        <Link to="/upgrade">
                                            <button className="btn">
                                                <img src={buttonUpgrade} width="100"/>
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <button className="btn p-0">
                                            <img src={buttonRelease} width="160" onClick={() => {
                                                if (merShow) {
                                                    setShowModalConfirm(true);
                                                } else {
                                                    setShowModalConfirm(false);
                                                    setShowModalFail(true);
                                                    setTextError("Please select the mercenary you want to decay.");
                                                }
                                            }}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showModalConfirm} id="upGradeModal" centered onHide={closeModalConfirm}>
                        <Modal.Body
                            className="materialReceived commonMessageRibbonFrame d-flex align-items-center justify-content-center">
                            <div className="textHeading font-riverAdventurer">Release</div>
                            <img className="closeModal" src={btc} alt="" onClick={closeModalConfirm}/>
                            <img
                                src={buttonRelease1 }
                                className="buttonAction"
                                onClick={async () => {
                                    await sign(data)
                                }}
                            />
                            <div>
                                <div className="text">Select materials to receive</div>
                                <div className="d-flex font-riverAdventurer">
                                    <div className="item" onClick={() => {
                                        setType('terminate');
                                        setTextSuccess('Belly')
                                    }}>
                                        <input type="radio" name="material"/>
                                            <div className="number">
                                                {bellyReceived}
                                                <img src={iconBelly} width="40"/>
                                            </div>
                                            <div className="status">Selected</div>
                                    </div>
                                    <div className="item" onClick={() => {
                                        setType('detached')
                                        setTextSuccess('Ori')
                                    }}>
                                        <input type="radio" name="material"/>
                                        <div className="number">
                                            {oriReceived}
                                            <img src={oriIcon} width="40"/>
                                        </div>
                                        <div className="status">Selected</div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={loading} id="commonMessageModal" size="lg">
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <div>
                                <p>Decaying...</p>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalFail} id="commonMessageModal" centered size="lg" onHide={closeModalFail}
                           animation={false}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalFail}/>
                            <div>
                                <p>{textError}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalSuccess} id="upGradeSuccessModal" centered onHide={closeModalSuccess}>
                        <Modal.Body className="messageStarFrame">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalSuccess}/>
                            <div>Decay successfully</div>
                            <div>The number of {textSuccess} you get is {amountReward}</div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default NewMyCrew;
