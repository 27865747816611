import Web3 from "web3";
import {useEffect, useState} from "react";
import logo from "../../../assest/img/logo.png"
import logoMetamask from "../../../assest/img/logo-metamask.png"
import btnClaim from "../../../assest/img/btn-claim.png"
import btnConnectWallet from "../../../assest/img/btn-connect-wallet.png"
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
import {ethers} from "ethers";
import '../../../assest/css/tokenVesting.css'

export default function Vesting() {
    const web3 = new Web3(Web3.givenProvider);
    const contract = new web3.eth.Contract(JSON.parse(process.env.REACT_APP_FOUNDER_ABI), process.env.REACT_APP_FOUNDER_ADDRESS);
    const [address, setAddress] = useState('');
    const [titleAlert, setTitleAlert] = useState('');
    const [lookedFullAmount, setLookedFullAmount] = useState(0);
    const [amountUnLook, setAmountUnLook] = useState(0);
    const [withdrewAmount, setWithdrewAmount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    useEffect(async () => {
        await getAmountLookedFullAmount();
        await getAmountUnLockAmount();
        await getWithdrewAmount();
    }, [address])

    async function connectWallet() {
        if (window.ethereum) {
            try {
                window.ethereum.enable().then(async function () {
                    window.ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                        if (accounts[0]) {
                            setAddress(accounts[0]);
                        }
                    })
                });
            } catch (e) {

            }
        } else if (window.web3) {
            window.ethereum.request({method: 'eth_requestAccounts'}).then(accounts => {
                if (accounts[0]) {
                    setAddress(accounts[0]);
                }
            })
        } else {
        }
    }

    async function withdrawBlockUnLook() {
        if (address > 0) {
            try {
                contract.methods.releaseAllMyToken().send({
                    from: address
                }).on("receipt", async (receipt) => {
                    console.log(receipt)
                    if (receipt.status) {
                        await getAmountUnLockAmount();
                        await getAmountLookedFullAmount();
                        await getWithdrewAmount();
                        setShowModal2(true);
                        setTitleAlert('Withdraw token success.');
                    }
                }).catch(err => {
                    setShowModal(true);
                    setTitleAlert('Withdraw token fail. Please try again later!');
                })
            } catch (e) {
            }
        }
    }

    const disconnectWallet = () => {
        setAddress('')
    }

    async function getAmountLookedFullAmount() {
        if (address.length > 0) {
            contract.methods.getLockedFullAmount(address).call(function (err, res) {
                if (err) {
                    return;
                }
                setLookedFullAmount(Number(ethers.utils.formatUnits(res.toString(), 18)));
            })
        }
    }

    async function getAmountUnLockAmount() {
        if (address.length > 0) {
            contract.methods.getAvailableAmount(address).call(function (err, res) {
                if (err) {
                    return;
                }
                setAmountUnLook(Number(ethers.utils.formatUnits(res.toString(), 18)));
            })
        }
    }

    async function getWithdrewAmount() {
        if (address.length > 0) {
            contract.methods.getWithdrewAmount(address).call(function (err, res) {
                if (err) {
                    return;
                }
                setWithdrewAmount(Number(ethers.utils.formatUnits(res.toString(), 18)));
            })
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const closeModal2 = () => {
        setShowModal2(false);
    }

    return (
        <body className="body-token-vesting">
            <div className="wrap-token-vesting d-flex flex-column justify-content-center align-items-center">
                <div className="logo-token-vesting">
                    <img src={logo} alt="" width="150px"/>
                </div>
                <div className="box-token-vesting">
                    <div className="text-center">
                        <h1 className="font-riverAdventurer-token-vesting">Creator Distribution Portal</h1>
                        {
                            address.length > 0 ? (
                                <div>
                                    <div className="walletAddress-token-vesting">
                                        <img src={logoMetamask} alt=""/>
                                        {address.slice(0, 4) + '...' + address.slice(address.length - 4, address.length)}
                                    </div>
                                    <div className="token-token-vesting">
                                        <div className="d-flex justify-content-between">
                                            <div>Total:</div>
                                            <div>{lookedFullAmount} Belly</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Remain:</div>
                                            <div>{lookedFullAmount - amountUnLook} Belly</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Available:</div>
                                            <div>{amountUnLook} Belly</div>
                                        </div>
                                    </div>
                                    <a
                                        href="#"
                                        className="claim-token-vesting"
                                        onClick={async () => {
                                            await withdrawBlockUnLook();
                                        }}
                                    >
                                        <img src={btnClaim} alt=""/>
                                    </a>
                                </div>
                            ) : (
                                <a
                                    href="#"
                                    className="connectWallet-token-vesting"
                                    onClick={async () => {
                                        await connectWallet();
                                        await getAmountUnLockAmount();
                                        await getAmountLookedFullAmount();
                                        await getWithdrewAmount();
                                    }}
                                >
                                    <img src={btnConnectWallet} alt=""/>
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
            <Modal show={showModal2} id="buyModal" onHide={closeModal2}>
                <Modal.Body className="messageStarFrame">
                    <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                    {titleAlert}
                </Modal.Body>
            </Modal>
            <Modal show={showModal} id="commonMessageModal" centered size="lg" onHide={closeModal}>
                <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                    <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                    <div>
                        <p>{titleAlert}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </body>
    );
}
